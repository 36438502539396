/**
 * = Heading DECORATION (mixin)
 *
 * Heading separator 'decoration'
 *
 * ========================================================================= */

@define-mixin heading-decoration {
	/* Decoration: use in PSEUDO EL  */
	content: "";
	display: block;

	/* background-color: var(--color-accent); */
	background-image: var(--heading-decoration);
	background-size: cover;
	width: 60px;
	height: 6px;
	margin: .75rem auto 1.5rem auto;
}
