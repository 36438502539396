/**
 * = REVIEW (component)
 *
 * Reviews consist of other components such as c-bio
 *
 * ========================================================================= */

.c-review {
	color: var(--color-text);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	& .c-bio__name {
		font-size: 1.25rem;
		font-weight: 300;
	}
}

