/**
 * = PADDED BOX (object)
 *
 * Simplest padded box Object.
 * Almost everything can be put in a box: text, images, etc.
 *
 * Optional extra-large padding.
 *
 * For more applications look for e.g. the `message` Component.
 *
 * ========================================================================= */


.o-boxed { @mixin boxed; }


/**
 * Boxed MODIFIERS
 * ----------------------------------------------------------------------------
 */

/**
 * xl
 */
.o-boxed--xl { padding: calc(var(--padding-main) * 2); }

