/**
 * = Hyperlink ITCSS <Base> Styles
 *
 * - Unstyled _elements_ only
 * - Inherits (some) from sanitize.css
 *
 * ========================================================================= */


:any-link {
	color: var(--color-link);
	text-decoration: none;
}

a:hover,
a:focus,
a:active { color: var(--color-link-hover); }

article p a:not([class*="button"]) { text-decoration: underline; }

/* Here Be Dragons */
a:active,
label:active,
button:active,
[type="button"]:active,
.c-button:active { outline: none; }

/* Transitions */
:any-link {
	transition: background-color .2s ease-in, color .2s ease-in;
}

/* FANCYY */
[id^="post-"].post-type-page p > a:not(.c-button),
.article-body p > a:not(.c-button) {
	&:link,
	&:visited,
	&:hover,
	&:active,
	&:focus {
		color: var(--color-text);
		text-decoration-skip-ink: none;
		text-decoration-skip: none;
		text-decoration: underline;
		text-decoration-color: rgba(var(--color-orange-val), .3);
		text-decoration-thickness: .5em;
		text-underline-offset: -.3em;
	}
	&:hover,
	&:active,
	&:focus {
		text-decoration-color: rgba(var(--color-orange-val), .6);
	}
}
