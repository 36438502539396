/**
 * = HEADING (mixin)
 *
 * Heading font-face, text transform and special font styles
 *
 * ========================================================================= */


@define-mixin heading {
	font-family: var(--ff-headings);
	text-transform: uppercase;

	/**
	 * NON-STANDARD, but allowed specifically in StyleLint
	 * @TODO: look into alternatives
	 */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-smooth: always;

	text-rendering: optimizeLegibility;
	text-rendering: geometricPrecision;
}
