/**
 * = SVG ICON sprite (object)
 *
 * SVG Icons are awesome. We use a ['SVG Icon Sprite'](https://css-tricks.com/svg-sprites-use-better-icon-fonts/) system
 * that uses the svg `use` element and an *external* SVG spritesheet.
 * You can find an example of our current Icons in `Public/FED/source/img/svg-icons/sprite/symbol/index.html`
 *
 * While building the icon set can be complex, using the icons is quite easy: add the proper `o-icon--xyz` class to
 * the `svg` classnames and add the proper `#xyz` id value on the `use` `href`.
 *
 * ### Alternative text
 *
 * Most icons are 'decorative': they are used in a context with other relevant text. However: sometimes icons are used stand-alone
 * without any other text (e.g. in buttons). With the latter, *alternative* text is needed through an `aria-label` attribute
 * on a parent element. Or you could use the `title` element in the `svg`.
 * See [this article](http://fvsch.com/code/svg-icons/how-to/#section-adding) and the example HTML.
 *
 * ### Technical: build system
 *
 * All this is build on the front-end through the npm `npm run build:svg` command, using [svg-sprite](https://github.com/jkphl/svg-sprite).
 *
 * To build locally you need to install `svg-sprite` **globally** with: `npm i -g svg-sprite`
 * Fallback support (for the external SVG sprite) comes through [svgxuse](https://github.com/Keyamoon/svgxuse)
 * More tips on using SVG for icons can be found [here](http://fvsch.com/code/svg-icons/how-to/).
 *
 * ### HTML: examples
 *
 *   <!-- Example Home icon without alternate text -->
 *   <a class="c-button" href="/">
 *   	<svg class="o-icon o-icon--home" aria-hidden="true">
 *   		<use xlink:href="/svg-icons/sprite.svg#home"></use>
 *   	</svg>
 *   	Homepage
 *   </a>
 *
 *   <!-- Example Star icon without alternate text -->
 *   <a class="c-button" href="/">
 *   	<svg class="o-icon o-icon--star" aria-hidden="true">
 *   		<use xlink:href="/svg-icons/sprite.svg#star"></use>
 *   	</svg>
 *   	Favorite
 *   </a>
 *
 *   <!-- Example Home icon with alternate text in aria-label -->
 *   <a class="c-button" href="/" aria-label="Homepage">
 *     <svg class="o-icon o-icon--home" aria-hidden="true">
 *       <use xlink:href="/svg-icons/sprite.svg#home"></use>
 *     </svg>
 *   </a>
 *
 *   <!-- Example Leaf icon with alternate text in Title attr. -->
 *   <a class="c-button" href="/">
 *     <svg class="o-icon o-icon--leaf">
 *       <title>Leaf</title>
 *       <use xlink:href="/svg-icons/sprite.svg#leaf"></use>
 *     </svg>
 *   </a>
 *
 * ========================================================================= */


[class*="o-icon-"],
.o-icon {
	display: inline-block;

	/* Allows sizing by changing the icon’s font-size

		@TODO: .o-icon { width: 1em; height: 1em; } should be critical CSS?

		width: 1em;
		height: 1em;
	 */
	width: 1.5em;
	height: 1.5em;


	/* Nice visual alignment for icons alongside text */

	/* vertical-align: -.15em; */
	/* vertical-align: bottom; */

	/* Default path fill = value of the color property */
	fill: currentColor;

	/* Paths and strokes that overflow the viewBox can show in IE.
	 If you use normalize.css, it already sets this. */
	/* overflow: hidden; */
}

/**
 * NOTE:
 * DEFAULT color (light grey)
 * UNLESS icons are direct children of interactive elements
 * .. then they inherit color
 */
.o-icon { color: var(--color-icon, currentColor); }
a, button, input, label { &  .o-icon { color: currentColor; } }

/**
 * SIZE
 * ------------------------------------------------------------------------- */

.o-icon--large { font-size: 1.5em; }


.o-media__img > .o-icon:only-child,
.o-row > .o-icon,
.o-row-list > * > .o-icon {
	&:first-child,
	&:last-child {
		font-size: 2rem;
		flex-shrink: 0;
	}
}


/**
 * COLORED
 * ------------------------------------------------------------------------- */

/* Accent */
/* .o-icon--color.o-icon--accent 			{ fill: var(--color-accent); } */

/* Social  */
/* .o-icon--color.o-icon--facebook-box 	{ fill: #3B5998; }
.o-icon--color.o-icon--google-plus-box 	{ fill: #DD4B39; }
.o-icon--color.o-icon--twitter-box 		{ fill: #55ACEE; }
.o-icon--color.o-icon--youtube-box		{ fill: #BB0000; }
.o-icon--color.o-icon--email-box		{ fill: var(--color-base); } */

h2 > .o-icon {
	width: 1.5rem;
	height: 1.5rem;

	&.o-icon-question {
		fill: var(--color-accent);
	}
}
