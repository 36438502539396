/**
 * = 360explore.com ITCSS Trip-facts Styles
 *
 * - Imported in site/index CSS
 * - trip-facts component-specific styles
 *
 * ========================================================================= */

/**
 * = Trip facts
 * Extend existing o-row-list
 * - NOTE: We know markup so can be quite speficic
 *
 * 1. Space around main Icon
 * 2. Align main info item left
 * 3. Help Icon
 * ------------------------------------------------------------------------- */

/* 1 */
.trip-features .o-icon:first-child,
.trip-day2day .o-icon:first-child {
	margin-left: var(--margin-horizontal);
	margin-right: var(--margin-horizontal);
	flex-shrink: 0;
}

/* 2 */
.trip-features div:nth-child(2),
.trip-day2day div:nth-child(2) { margin-right: auto; }

/* 3 */
.trip-features .u-h2 .o-icon-question {
	margin: 0;
	font-size: .5em;
	vertical-align: super;
}

.trip-facts > .l-grid  { grid-template-columns: 1fr; }

@media (min-width: 40em) {
	.trip-facts > .l-grid  {
		grid-template-columns: 1fr 1fr;
	}
}

@media (min-width: 60em) {
	.trip-facts > .l-grid  {
		max-width: 85%;
		grid-template-columns: 58% 1fr;
	}
}

/**
 * = Trip Features
 * ------------------------------------------------------------------------- */

.trip-features .sub + .u-h2 { font-weight: 200; line-height: 1; }
.trip-features a > .o-icon-question { font-size: 20px; }
.trip-features .u-h2 abbr { text-transform: lowercase; }

/* Move Rating below heading on narrow screens */
.trip-features li {
	position: relative;
	margin-top: 0;

	& .c-rating {
		--rating-size: 1.5em;
		position: absolute;
		top: .5em;
		right: 0;
	}
}

/* Undo above: */
@media (min-width: 30em) {
	.trip-features li .c-rating {
		--rating-size: 2em;
		position: static;
	}
}

/* @media (min-width: 40em) {
	.trip-features .c-rating { margin-bottom: -.5em; }
} */

.trip-ctas .c-button {
	margin: .5rem;
}
