/**
 * = ROW (object)
 *
 * Can be used for about anywhere you need some flexbox row
 * of centered items.
 *   | [one]   [two]   [three] |
 *
 * ========================================================================= */


.o-row,
.o-row-list > * {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: var(--margin-horizontal);
}

.o-row-list {
	list-style-type: none;
	padding-left: 0;

	& > *:before { content: none; }
}

/* Undo Lobotomized Owl */
.o-row > *,
.o-row-list > * > *,
.o-row__item {
	display: block;
	margin-top: 0;
}


/**
 * Modifiers
 * 1. ROW: Left Aligned
 * 2. ROW: Left Aligned
 * 3. ROW: Top Aligned
 * 4. ROW: Bottom Aligned
 * 5. ITEM: Main (larger)
 * 6. ITEM: Left
 * 7. ITEM: Right
 * 8. ITEM: Top
 * 9. ITEM: Bottom
 * ----------------------------------------------------------------------------
 */

/* 1 */
.o-row--start,
.o-row-list--start { justify-content: flex-start; }

/* 2 */
.o-row--end,
.o-row-list--end { justify-content: flex-end; }

/* 3 */
.o-row--top,
.o-row-list--top { align-items: flex-start; }

/* 4 */
.o-row--bottom,
.o-row-list--bottom { align-items: flex-end; }

/* 5 */
.o-row__item--main { flex-basis: 80%; }

/* 6 */
.o-row__item--start { margin-right: auto; }

/* 7 */
.o-row__item--end { margin-left: auto; }

/* 8 */
.o-row__item--top { align-self: flex-start; }

/* 9 */
.o-row__item--bottom { align-self: flex-end; }


.o-row--wrap { flex-wrap: wrap; }
.o-row--gap-none { gap: 0; }
