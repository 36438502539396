/**
 * = COVER (mixin)
 *
 * Used (mainly) on a Pseudo Element
 * Covers area of complete (positioned) ancestor
 *
 * ========================================================================= */


@define-mixin cover {
	/* Necessary for Pseudo Elements */
	content: "";
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
