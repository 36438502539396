/**
 * = ITCSS <Grid> Layout Styles
 *
 * Inspiration:
 * - https://every-layout.dev/layouts/grid/
 * - https://web.dev/one-line-layouts/
 * - https://css-tricks.com/snippets/css/css-grid-starter-layouts/
 * -
 *
 * ========================================================================= */


/**
 * MAIN
 * Assume we need a TWO column grid
 * ------------------------------------------------------------------------- */
.l-grid {
	display: grid;
	gap: var(--grid-gap);
	grid-template-columns: repeat(var(--grid-column-count, auto-fit), minmax(var(--grid-column-min, 18em), 1fr));

	list-style-type: none;
	padding: 0;
	margin-bottom: var(--margin-main);

	/* UNDO Lobotomized Owl */
	& > * { margin-top: 0; }
}

/* .l-grid__unit {} */

/**
 * Modifiers
 * [1] Fixed two-columns
 * [2] Fixed three-columns
 * [3] Fixed four-columns
 * [4] Fixed five-columns
 * [5] Sidebar (left)
 * ------------------------------------------------------------------------- */
/* [1], [2], [3] */
.l-grid--two-col   { --grid-column-count: 2; --grid-column-min: 0; }
.l-grid--three-col { --grid-column-count: 3; --grid-column-min: 0; }
.l-grid--four-col  { --grid-column-count: 4; --grid-column-min: 0; }
.l-grid--five-col  { --grid-column-count: 5; --grid-column-min: 0; }

.l-grid--sidebar {
	--grid-column-count: 1;

	@media (min-width: 60em) {
		& {
			--grid-column-count: 2;
			grid-template-columns: 1fr 6fr;
		}
	}
}

/**
 * Same as above, but NOT fixed
 * .. falling back to less columns < 960 (60rem)
 *
 * MAKE THIS DEFAULT (and mobile-first)?
 */
@media (max-width: 60em) {
	/* .l-grid--mq-wide {
		&.l-grid--two-col,
		&.l-grid--three-col,
		&.l-grid--four-col { --grid-column-count: auto-fit; --grid-column-min: 18rem; }
	} */

	/* Default now (mobile-last) */
	.l-grid--two-col,
	.l-grid--three-col,
	.l-grid--four-col,
	.l-grid--five-col { --grid-column-count: auto-fit; --grid-column-min: 18rem; }
}

/* [5] */
.l-grid--flush { gap: 0; }

/**
 * Modifier 'Utility'
 * Center one item
 * ------------------------------------------------------------------------- */
.u-center-object {
	display: grid;
	place-items: center;
}
