/**
 * = 360explore.com ITCSS Trip-Day2Day Styles
 *
 * - Imported in site/index CSS
 * - trip-day2day component-specific styles
 *
 * ========================================================================= */

/**
 * = Trip Day2Day
 * ------------------------------------------------------------------------- */

#trip-days {
	background-image: var(--background-deco-wide, none), url('/wp-content/themes/360explore-com/static/img/backgrounds/day-to-day.jpg');
	background-size: var(--background-deco-wide-size), contain;
	background-repeat: repeat-x, no-repeat;
	background-attachment: scroll, fixed;
	background-position: 0 calc(100% + 2px), 100% 100%;
	padding-top: 6rem;
	padding-bottom: 6rem;
	margin-top: 0;
}

.trip-day2day {

	& details {
		/* Dots.. */
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='16' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='#E2E5E6' cx='3' cy='3' r='3'/%3e%3c/svg%3e");
		background-size: 10px;
		background-position: 1.33rem 0;
		background-repeat: no-repeat round;
		padding-bottom: 4.5rem;
	}

	/* No Dots on last item */
	& li:last-child details { background-image: none; }

	& summary {
		flex-wrap: nowrap;
		background-position: 100% .8rem;

		& .o-icon.o-icon {
			/* Background to mask Dots */
			background-color: #FFF;
			border-radius: 5px;
			align-self: flex-start;
			margin-left: 0;
		}

		& + div {
			margin-left: 5rem;
			max-width: 80ch;
		}
	}

	& summary:focus {
		outline: none;
	}

	& h3 {
		/* font-size: 20px; */
		font-weight: 300;
	}
	& h3 span {
		font-weight: 900;
		margin-left: .25em;

		&::before {
			content: "//";
			color: var(--color-accent);
			margin-right: .3em;
		}
	}

	& details > div > * + * {
		margin-top: .75rem;
	}

	/* Fix WP fckups */
	& details > div > p:empty { display: none; }

	& details ul {
		list-style-type: disc;
		list-style-position: outside;
	}
}
