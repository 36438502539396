/**
 * = 360explore.com ITCSS <SITE Logo> Styles
 *
 * - LOGO is inlined SVG
 *
 * ========================================================================= */


/**
 * = LOGO (mobile 1st)
 *	 Offset slightly over header
 * ------------------------------------------------------------------------- */
.site-logo {
	position: absolute;
	top: -.5rem;
	left: calc(50% - (var(--width-logo) / 2));
	z-index: 2;

	@media (min-width: 40em) {
		& {
			--width-logo: 112px;
			top: 1rem;
		}
	}
}

.site-logo__link {
	width: var(--width-logo);
	padding: 0;
}

@media (min-width: 60em) {
	.site-logo__link:focus {
		outline: none;
		& svg { transform: scale(.75); }
	}
	.site-logo__link:focus::before {
		content: attr(title);
		background-color: var(--color-link);
		color: var(--color-white);
		position: absolute;
		left: 0;
		top: 1rem;
		right: 0;
		font-size: 1rem;
		text-align: center;
		z-index: 1;
		border-radius: 3px;
	}
}

.site-logo__image {
	filter: drop-shadow(0 2px 4px rgba(0, 0, 0, .2));
	max-width: 100%;
}
