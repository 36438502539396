/**
 * = Headings ITCSS <Base> Styles
 *
 * - Unstyled _elements_ only
 * - Inherits (some) from sanitize.css
 *
 * ========================================================================= */


h1, .u-h1, h2, .u-h2, h3, .u-h3, h4, .u-h4, h5, .u-h5, h6, .u-h6 {
	font-weight: normal;
	margin-bottom: 0;
}

h1, .u-h1, h2, .u-h2, h3, .u-h3, h4, .u-h4 {
	@mixin heading;
}

h1, .u-h1 { font-size: var(--font-size-h1); font-weight: 200; }
h2, .u-h2 { font-size: var(--font-size-h2); font-weight: 900; line-height: 1.2; }
h3, .u-h3 { font-size: var(--font-size-h3); font-weight: 900; line-height: 1.2; }
h4, .u-h4 { font-size: var(--font-size-h4); line-height: 1.2; }
h5, .u-h5 { font-size: var(--font-size-h5); }
h6, .u-h6 { font-size: var(--font-size-h6); }

.section-heading,
.c-deco-heading {
	font-size: var(--font-size-h2);
	font-weight: 900;
	text-transform: uppercase;
	text-align: center;

	&::after {
		@mixin heading-decoration;
	}
}
.section-heading::after {
	width: 90px;
	height: 10px;
}
.c-deco-heading::after {
	margin: .25rem 0 .5em 0;
}

@media (min-width: 40em) {
	.section-heading,
	.c-deco-heading { font-size: var(--font-size-hero); }
}
@media (min-width: 60em) {
	.section-heading { font-size: var(--font-size-huge); }
}
