/**
* = 360explore.com ITCSS <SITE Theme> Styles
*
* - Imported in main index CSS
* - site-specific styles
*
* ========================================================================= */

/**
* = SECTIONS
* ------------------------------------------------------------------------- */
/* .site-header,
.site-content,
.site-footer {
} */
.site-content {
	padding-top: 3rem;
}


/*
.site-meta {}
.site-navigation {}
.site-content {}
*/

.site-footer-meta {
	& li, & p { font-size: .865rem; }
}

/**
* = MAIN
* ------------------------------------------------------------------------- */
/* .site-content { } */


/**
* = DARK Sections
* ------------------------------------------------------------------------- */
.site-section--dark {
	background-color: var(--color-grey);
	color: var(--color-white);
	padding-top: var(--padding-main);
	padding-bottom: var(--padding-main);

	& a { color: inherit; }
	& a:active,
	& a:focus,
	& a:hover {
		color: var(--color-link);
	}
}


/* Add background gradient for footer */
/* .site-footer {} */


/**
* = FOOTER (NewsLetter section)
* ------------------------------------------------------------------------- */
.site-footer .l-section:nth-child(1) {
	--bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-mobile-480x480.jpg');
	--background-size: contain;

	text-align: center;

	/* 66.66% ~= ratio of background-image */
	padding-bottom: 60%;
	background-image: var(--background-deco-narrow-grey, none), var(--bg-image, none);
	background-size: var(--background-deco-narrow-size), var(--background-size, contain);
	background-position: var(--background-deco-narrow-position), 50% 100%;
	background-repeat: repeat-x, no-repeat;

	@media (min-width: 30em) {
		& {
			--bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-mobile-960x960.jpg');
			background-image: var(--background-deco-wide-grey, none), var(--bg-image, none);
			background-size: var(--background-deco-wide-size), var(--background-size, contain);
		}
	}

	@media (min-width: 34em) and (orientation: landscape)  { & { --bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-720x480.jpg'); } }
	@media (min-width: 40em)  { & { padding-bottom: 45%; } }
	@media (min-width: 60em) and (orientation: portrait)   { & { --bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-mobile-1707x1707.jpg'); --background-size: cover; } }
	@media (min-width: 67em) and  (orientation: landscape) { & { --bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-1440x960.jpg'); } }
	@media (min-width: 75em) { & { --bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-1920x1280.jpg'); --background-size: cover; } }
	@media (min-width: 140em) {& { --bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-2560x1707.jpg'); } }

	/* @2X */
	/* @media (min-width: 30em) and (-min-device-pixel-ratio: 2) { & { --bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/footer-image-mobile-960x960.jpg'); } } */

	& [rel="home"] {
		display: inline-block;
		margin-bottom: var(--margin-main);
	}

	& .c-social-media-list {
		margin-top: var(--margin-main);
		& a {
			color: var(--color-grey-medium);
			&:hover,
			&:focus,
			&:active { color: var(--color-grey); }
		}
		& .o-icon { font-size: 1.5em; }
	}

	@media (min-width: 40em) {
		& .section-heading {
			font-size: var(--font-size-huge);
		}
	}

	@media (min-width: 60em) {
		& {
			text-align: left;
			display: grid;
			grid-template-columns: 1fr 4fr 3fr 1fr;
			column-gap: var(--grid-gap);


			& .section-heading {
				text-align: left;
				&::after { margin-left: 0; }
			}

			& [rel="home"] { margin: 0; }

			& .section-heading,
			& .section-heading + .u-h1 { grid-column: 2; }
			& .section-heading + .u-h1 ~ * { grid-column: 3; }

			& form {
				grid-row: 1;

				margin-top: 1em;
			}
		}
	}
}


/**
* = FORM (NewsLetter)
* ------------------------------------------------------------------------- */
@media (max-width: 60em) {
	.site-footer form {
		padding: var(--margin-vertical);

		& input {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}


/**
* = MENU LINKS
* ------------------------------------------------------------------------- */
#site-footer-links {
	& li,
	& p { font-size: 1rem; }
}


/**
* = PARTNERS
* ------------------------------------------------------------------------- */
.c-list-partners {
	text-align: center;

	@media (min-width: 80em) { & { text-align: left; } }

	& li {
		width: 90px;
		margin-top: var(--margin-main);
		margin-bottom: var(--margin-main);
		text-align: center;
		& + li:not(:last-child) { margin-left: 1.5rem; }
	}

	& a:hover,
	& a:focus,
	& a:active { & img { filter: brightness(1.25); } }

}

/**
* = INTRO (component)
*
* ========================================================================= */

.article-body { overflow: hidden; }

@media (min-width: 35em) {
	.c-intro,
	.single-trip .article-body > div,
	.post-type-post .article-body,
	.post-type-page {
		& > p:first-of-type {
			font-size: var(--font-size-h3);
			font-weight: 600;
		}
	}
}

@media (min-width: 70em) {
	.article-body {
		position: relative;

		&::before {
			content: "";
			width: .75rem;
			height: 100%;
			background-color: var(--color-accent);
			position: absolute;
			left: 0;
			--extra: calc(calc(100vw - var(--width-page)) / 2);
			left: calc(var(--extra) - 6rem);
		}
	}
}

.trip-highlights {
	margin-top: 6rem;
	& h3 {
		font-size: var(--font-size-hero);
		color: var(--color-accent);
	}

	& .c-list {
		list-style-type: none;

		& li {
			font-family: var(--font-family-alt);
			font-size: var(--font-size-h2);
			font-weight: 300;

			/* font-style: italic; */
			line-height: 1.2;
			color: var(--color-grey-medium);
		}
	}
}

@media (min-width: 60em) {
	.single-trip .article-body {
		display: grid;
		grid-template-columns: 4fr 2fr;
		grid-gap: 0 calc(var(--grid-gap) * 2);
	}

	.trip-highlights { margin-top: 0; }
}

.trip-intro {
	--width-page: var(--width-page-inner, 928px);
}
@media (min-width: 40em) {
	.trip-intro {
		--width-page: 90rem;
		display: grid;
		grid-template-columns: 2fr 4fr;
		grid-gap: 0 var(--grid-gap);
		align-items: center;

		& .c-bio,
		& .c-bio__link { align-items: flex-start; }
		& .c-bio { margin-left: 7.5rem; }

		& .c-bio__link::after { margin: .75rem 0; }

		& .c-intro {
			max-width: 45rem;
			margin-top: 0;
		}
	}
}

/**
* = GALLERY
*
* ========================================================================= */
#trip-gallery {
	& img { width: 100%; }
	& .l-grid { margin-bottom: 0; }
	@media (min-width: 160em) {
		& {
			max-width: 160rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}


/**
* = BLOCKS mask/overlay
*
* ========================================================================= */
.c-block-mask {
	background-image: var(--background-blocks-flipped, none), var(--background-blocks, none), var(--bg-image, none);
	background-size: calc(100% + 2px), calc(100% + 2px), var(--background-size, cover);
	background-position: 50% -1px, 50% calc(100% + 1px), var(--background-position, 50% 100%);
	background-repeat: no-repeat;
	padding: calc(var(--padding-main) * 4) calc(var(--padding-main) * 2);
}
.c-block-mask--grey-light { background-color: var(--color-grey-light); }


/**
* = TRIP: in/exclusives
* ========================================================================= */

#trip-inclusives {
	& .c-list {
		& li {
			/* list-style-image: var(--data-check-white); */
			list-style-type: none;
			margin-left: 1.5em;

			&::before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				background-image: var(--data-check-white);
				background-repeat: no-repeat;
				width: 1.5em;
				height: 1em;
				margin-left: -1.5em;
			}
		}
	}
	& .l-section--inverted .c-list li::before {
		background-image: var(--data-cross-white);
	}
}


/**
* = TRIP QUERY LIST: cards list in sidebar/grid
* ========================================================================= */
/* OVERRIDE trip-results: later to 3 col */
@media (min-width: 60em) {
	#trip-query-results > .l-grid--three-col { --grid-column-count: 2; }
}
@media (min-width: 86.5em) {
	#trip-query-results > .l-grid--three-col { --grid-column-count: 3; }
}


/* DEBUG */
/* .wp-block-embed-smugmug,
p:empty {
	outline: 2px dashed red;
} */


/* WP Embeds */
.wp-block-embed iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}
.wp-block-embed__wrapper {
	position: relative;
	padding-top: 66.66%;
}
.wp-embed-aspect-16-9 .wp-block-embed__wrapper { padding-top: calc((9/16) * 100%); }
.wp-embed-aspect-4-3 .wp-block-embed__wrapper { padding-top: calc((3/4) * 100%); }

/* Undo Lobotomized Owl */
@media (min-width: 600px) {
	.wp-block-column + .wp-block-column { margin-top: 0; }
}
.CookieDeclaration * + * { margin-top: auto; }

/* AVATARS */
img.avatar,
.c-avatar { border-radius: 100%; }

/* WP BLOCK BUTTONS */
.wp-block-button__link {
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background-color: var(--color-link);
	color: var(--color-white);
	padding: calc(var(--padding-main) / 2) var(--padding-main);
	border: 1px solid transparent;
	border-radius: var(--button-radius, var(--border-radius));
	min-width: 10em;
	box-shadow: none;
	transition: background-color .2s ease-in, opacity .2s ease-in;

	&:active,
	&:focus,
	&:hover {
		background-color: var(--color-grey);
		color: var(--color-white);
		outline: none;
	}

}

/**
* = GUIDES BAR:
* ========================================================================= */

#guides-bar,
.guides-bar {
	text-align: center;

	& > h2 + p { margin-top: 0; }
	& > h2 + p + p { margin-top: var(--margin-vertical); }

	& > .o-row {
		flex-wrap: wrap;
		max-width: 40em;
		margin: 3rem auto 0 auto;
	}

	& .c-avatar + p {
		font-weight: 600;
		color: var(--color-text);
		margin-top: .5em;
	}
	& :hover > p {
		color: var(--color-link);
	}
}
.guides-bar.l-section--grunge {
	padding-top: 5rem;
	padding-bottom: 5rem;
}

@media (max-width: 27.5em) {
	#guides-bar,
	.guides-bar {
		& > .o-row > :nth-child(3) ~ * { display: none; }
	}
}
