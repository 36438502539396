/**
 * = LIST RESET (mixin)
 *
 * Removes default list styling
 *
 * ========================================================================= */


@define-mixin list-reset {
	list-style: none;
	padding-left: 0;
}
