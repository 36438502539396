/**
 * = SHOW/HIDE (utility)
 *
 * Shows or Hides content based on media queries
 *
 * - Mobile first
 *
 * - narrow
 * - medium
 * - wide
 *
 * ========================================================================= */


/* stylelint-disable declaration-no-important  */
.u-show-narrow,
.u-show-narrow-only { display: block !important; }

.u-hide-narrow,
.u-hide-narrow-only,
.u-show-medium,
.u-show-medium-only,
.u-show-wide,
.u-show-wide-only { display: none !important; }


/**
 * = MEDIA QUERIES
 * --------------------------------------------------------------------- */

/* Medium */
@media (min-width: 48em) {
	.u-show-medium,
	.u-show-medium-only,
	.u-hide-narrow-only,
	.u-hide-wide-only { display: block !important; }

	.u-show-narrow-only,
	.u-hide-medium,
	.u-show-wide { display: none !important; }
}

/* Wide */
@media (min-width: 60em) {
	.u-show-wide,
	.u-show-wide-only,
	.u-hide-narrow-only,
	.u-hide-medium-only { display: block !important; }

	.u-hide-wide,
	.u-show-narrow-only,
	.u-show-medium-only { display: none !important; }
}

/* stylelint-enable */
