/**
 * = text ALIGNMENT (utility)
 *
 * ========================================================================= */


/* stylelint-disable declaration-no-important  */

.u-align-left { text-align: left !important; }

.u-align-right { text-align: right !important; }

.u-align-center { text-align: center !important; }


.u-margin-top { margin-top: var(--margin-main) !important; }
.u-margin-bottom { margin-bottom: var(--margin-main) !important; }

/* stylelint-enable */
