
.c-spinner-parent {
	position: absolute;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, .7);
	display: grid;
	align-items: center;
	z-index: 10;
}

/*
https://projects.lukehaas.me/css-loaders/
*/
.c-spinner,
.c-spinner:before,
.c-spinner:after {
	background: #FFF;
	animation: l1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}
.c-spinner {
	color: #FFF;
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	transform: translateZ(0);
	animation-delay: -.16s;
}
.c-spinner:before,
.c-spinner:after {
	position: absolute;
	top: 0;
	content: '';
}
.c-spinner:before {
	left: -1.5em;
	animation-delay: -.32s;
}
.c-spinner:after {
	left: 1.5em;
}
@-webkit-keyframes l1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
@keyframes l1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
