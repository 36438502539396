/**
 * = DEPARTURE(component)
 *
 * The `Departure` Component is like a table
 * However, it's more flexible and uses a DL ;)
 *
 * ========================================================================= */

.c-departure {
	--space-vertical: 12px;
	--space-horizontal: 20px;
	--status-color: var(--color-green);

	background-color: var(--color-white);
	border-left: 16px solid var(--status-color);
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);
}
.c-departure + .c-departure {
	margin-top: 3.75rem;
}

.c-departure__val {
	& .o-icon-gender-male,
	& .o-icon-gender-female,
	& .o-icon-cross {
		vertical-align: middle;
		margin-left: .25em;
	}

	& .o-icon-cross {
		width: 1.2em;
		height: 1.2em;
	}
}

.c-departure__heading,
.c-departure__body,
.c-departure__cta,
.c-departure__extra {
	padding: var(--space-vertical) var(--space-horizontal);
}

.c-departure__heading {
	background-color: var(--color-grey);
	color: var(--color-white);
	font-size: var(--font-size-h2);

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.c-departure__heading .to {
	font-family: sans-serif;
	font-size: var(--font-size-para);

	&::before {
		content: "\25B6 \FE0E";
		color: var(--color-accent);
	}
}

.c-departure__time {
	line-height: 1.33;

	& span {
		display: block;
		text-transform: capitalize;
		font-size: var(--font-size-para);
		font-style: italic;
		font-weight: 300;
	}

	& :last-child span { text-align: right; }
}

.c-departure__body {
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	/* grid-auto-rows: minmax(80px, auto); */
	align-items: stretch;
}

.c-departure__key,
.c-departure__val {
	grid-column: 1 / span 2;
	align-content: baseline;
	padding-top: var(--space-vertical);
	padding-bottom: var(--space-vertical);
	border-bottom-width: 2px;
}
.c-departure__val {
	grid-column-start: 3;
	font-weight: 800;
	text-align: right;
	text-transform: uppercase;
}
.c-departure__key > span,
.c-departure__val > span,
.c-departure__val--guide a {
	display: block;
	line-height: 1.2;
}
.c-departure__val--price {
	font-size: 25px;
}

/* Align label with larger value of 25px */
.c-departure__key--price {
	padding-top: 18px;
}

.c-departure__val--status {
	color: var(--status-color);
}

.c-departure__key--guide {
	/* Align label with larger value because of Avatar */
	padding-top: 7px;
	padding-bottom: calc(var(--space-vertical) / 2);
	grid-column: 1 / 2;
}
.c-departure__val--guide {
	padding-top: 0;
	padding-bottom: calc(var(--space-vertical) / 2);
	grid-column: 2 / span 3;

	& a {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: .5em;
	}

	& a span { line-height: 1; }

	/* & .c-avatar { } */
}
.c-departure__val--discount {
	grid-column-end: 5;
	text-transform: none;
}

.c-departure__footer p {
	text-align: center;
}
.c-departure__extra {
	background-color: rgb(187, 195, 197);
	color: var(--color-white);

	& .c-button {
		color: var(--color-white);
		opacity: 1;

		&:hover,
		&:focus,
		&:active {
			color: var(--color-link-hover);
		}
	}
}

/**
  * Modifiers
  * 1. Departure 'Status'
  * ------------------------------------------------------------------------- */

/* 1 */
.c-departure--planned { --status-color: var(--color-green); }
.c-departure--guaranteed,
.c-departure--guaranteed-1,
.c-departure--guaranteed-2,
.c-departure--full-1,
.c-departure--full-2 { --status-color: var(--color-orange); }
.c-departure--departed { --status-color: var(--color-grey-medium); }
.c-departure--full { --status-color: var(--color-red); }
.c-departure--returned { --status-color: var(--color-red); }

/* SPAN 100% width */
.c-departure__val--span {
	grid-column-start: 1;
	grid-column-end: -1;
	font-weight: 400;
	text-align: left;
}


/**
  * MEDIA QUERIES
  * ------------------------------------------------------------------------- */

@media (min-width: 50em) {
	.c-departure {
		--space-horizontal: 40px;
		position: relative; /* To abspos __cta */
	}

	.c-departure__heading {
		padding-right: calc(50% + 40px);
	}

	.c-departure__body {
		grid-template-columns: 1fr 1fr 80px 1fr 1fr;
	}
	.c-departure__cta {
		text-align: right;
		padding: 0;
		position: absolute;

		/* top: var(--space-vertical); */
		top: calc(var(--space-horizontal) / 2);
		right: var(--space-horizontal);
	}
	.c-departure__cta .c-button:not(.c-button--inactive) {
		&:hover,
		&:focus,
		&:active {
			border-color: var(--color-white);
		}
	}

	/* RE-ORDER 'COLUMNS' dt.class needed for extra specificity */

	/* Row 1 */
	.c-departure__key--guide,
	.c-departure__key--status {  &, & + dd { grid-row-start: 1; } }

	/* Row 2 */
	.c-departure__key--duration,
	.c-departure__key--price {  &, & + dd { grid-row-start: 2; } }

	/* Row 3 */
	.c-departure__key--age,
	.c-departure__key--discount {  &, & + dd { grid-row-start: 3; } }

	/* ..except when there is no 'Price' row */
	.c-departure__val--status + .c-departure__key--discount {  &, & + dd { grid-row-start: 2; } }

	/* Row 4 */
	.c-departure__key--group {  &, & + dd { grid-row-start: 4; } }

	/* ..except when there is no 'Age' row */
	.c-departure__key--group:nth-of-type(3) {  &, & + dd { grid-row-start: 3; } }

	/* LEFT Column */
	.c-departure__key--guide,
	.c-departure__key--duration,
	.c-departure__key--age,
	.c-departure__key--group {
		grid-column-start: 1;

		& + dd {
			grid-column-start: 2;
			grid-column-end: 3;
		}
	}

	/* RIGHT Column */
	.c-departure__key--status,
	.c-departure__key--price,
	.c-departure__key--discount {
		grid-column-start: 4;

		& + dd {
			grid-column-start: 5;
			grid-column-end: 6;
		}
	}

	/* 7. Discount VAL SPANS 2 cells */
	.c-departure__key--discount + dd {
		grid-column-start: 4;
		grid-column-end: 6;
	}

	/* FIX:SPAN --guide */
	.c-departure__key--guide + dd,
	.c-departure__key--group + dd { grid-column-start: 1; }
}

/* Fix 'Group' */
@media (max-width: 30.5em) {
	.c-departure__heading {
		font-size: 18px;
		& .to { font-size: 12px; }
	}
	.c-departure__key--group,
	.c-departure__val--group { grid-row: 3; }
	.c-departure__val--group {
		grid-column: 2 / -1;

		& .o-icon {
			transform: scale(.8);
			overflow: visible;
			margin: 0;
		}
	}
}
