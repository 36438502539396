/**
 * = Forms ITCSS <Base> Styles
 *
 * - Imported in main index CSS
 * - Unstyled _elements_ only
 * - Inherits (some) from sanitize.css
 *
 * ========================================================================= */

form {
	color: var(--color-grey);
}

fieldset,
legend,
label,
input,
select,
option,
textarea { color: inherit; }

label { font-weight: 400; }

fieldset {
	padding: 0;
	border-color: var(--color-border);
	margin-left: 0;
	margin-right: 0;
}

input,
button,
select, textarea {
	font-family: var(--font-family);
	font-size: 1em;
}

select, textarea { border-width: 1px; }

/* FOCUS */
input, button, select, textarea {
	&:focus,
	&:focus-visible {
		/* outline-style: solid; outline-width: 3px; */
		outline: none;
		box-shadow: 0 0 3px var(--color-focus);
	}
}


legend,
.legend,
.c-form__heading {
	display: block;
	width: 100%;

	/* font-size: var(--font-size-h3); */
	font-weight: 600;
	text-transform: uppercase;
	color: var(--color-grey);
	padding: 0 0 .5em 0;
	border-bottom: 1px solid var(--color-border);

	&:focus,
	&:focus-visible {
		/* :"( */
		outline: none;
		color: var(--color-accent);
	}
}

fieldset > label,
.o-label--flex {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

label.c-switch-all {
	color: var(--color-accent);
	text-transform: uppercase;
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="range"],
input[type="time"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
select,
textarea,
.o-form__input {
	padding: .361em 1.5em;

	background-color: #EEE;

	/* border-width: 1px;
	border-radius: var(--button-radius); */

	/* TODO: needed? */
	font-family: var(--font-family);
	font-size: var(--font-size-para);
	font-weight: 300;
	line-height: var(--line-height);
}


/**
 * Custom Styles for Radio, Checkbox, c-switch
 * https://css-tricks.com/custom-styling-form-inputs-with-modern-css-features/
 * https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
 */
/* stylelint-disable property-no-vendor-prefix  */
@supports(-webkit-appearance: none) or (-moz-appearance: none) {
	input[type="checkbox"],
	input[type="radio"] {

		--custom-input-active: var(--color-link);
		--custom-input-active-inner: #FFF;
		--custom-input-border: var(--color-border);
		--custom-input-border-hover: var(--color-link);
		--custom-input-background: var(--color-grey-light);
		--custom-input-disabled: #F3F3F3; /* #F6F8FF; */
		--custom-input-disabled-inner: #F0F0F0; /* #E1E6F9; */

		-webkit-appearance: none;
		-moz-appearance: none;

		outline: none;
		cursor: pointer;

		display: inline-block;
		vertical-align: middle;
		height: 1.5em;

		/* border: 1px solid var(--cur-border, var(--custom-input-border)); */
		background-color: var(--cur-background, var(--custom-input-background));
		position: relative;
		margin: 0;

		transition: background-color .3s, border-color .3s, box-shadow .2s;

		&::after {
			content: "";
			display: block;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform .3s ease, opacity .2s;
		}


		&:checked {
			/* --cur-border: var(--custom-input-active); */
			--cur-background: var(--custom-input-active);
		}

		&:disabled {
			--cur-background: var(--custom-input-disabled);
			cursor: not-allowed;
			opacity: .9;

			&:checked {
				/* --cur-border: var(--custom-input-border); */
				--cur-background: var(--custom-input-disabled-inner);
			}

			& + label {
				cursor: not-allowed;
			}
		}

		&:hover {
			&:not(:checked) {
				&:not(:disabled) {
					/* --cur-border: var(--custom-input-border-hover); */
					--cur-background: var(--color-grey-medium);
				}
			}
		}

		&:focus,
		&:focus-visible {
			/* --custom-input-active-inner: var(--color-focus); */
			box-shadow: 0 0 0 2px #FFF, 0 0 0 4px var(--color-focus);
		}

		&:not(.c-switch) {
			width: 1.5em;

			&:after { opacity: var(--cur-opacity, 0); }
			&:checked { --cur-opacity: 1; }
		}
	}

	input[type="checkbox"] {
		&:not(.c-switch) {
			border-radius: var(--border-radius);

			&:after {
				width: .4em;
				height: .7em;
				border: 2px solid var(--custom-input-active-inner);
				border-top: 0;
				border-left: 0;
				left: .5em;
				top: .15em;
				transform: rotate(var(--cur-radius, 20deg));
			}

			&:checked { --cur-radius: 43deg; }
		}

		/* Checkbox as Switch */
		&.c-switch {
			width: 2.5em;
			height: 1.25em;
			margin-top: .125em;
			margin-bottom: .125em;
			border-radius: 1em;

			&:after {
				left: .125em;
				top: .125em;
				border-radius: 50%;
				width: 1em;
				height: 1em;
				background: var(--custom-input-active-inner);
				transform: translateX(var(--cur-position-x, 0));
			}

			&:checked { --cur-position-x: 1.25em; }

			&:disabled {
				&:not(:checked) {
					&:after {
						opacity: .6;
						background-color: var(--custom-input-border);
					}
				}
			}
		}
	}

	input[type="radio"] {
		border-radius: 50%;
		&:after {
			width: .75em;
			height: .75em;
			left: .375em;
			top: .375em;
			border-radius: 50%;
			background: var(--custom-input-active-inner);
			opacity: 0;
			transform: scale(var(--cur-scale, .5));
		}

		&:checked { --cur-scale: 1; }
	}
}
/* stylelint-enable  */

input[type="search"] {
	text-transform: uppercase;
	width: 100%;
}

/**
 * = TRIP FILTERS
 * ========================================================================= */
form[name="find-trips"] {

	& [type="submit"],
	& [type="reset"] { min-width: auto; }

	& [for="trip-query"] + span {
		margin-top: 0;
	}
}

#trip-query {
	max-width: 72%;
	margin-top: 0;
	margin-right: .75rem;
}

#trip-query-confirm,
#trip-query-confirm + input {
	margin: 1.5rem 1.5rem 0 0;
}

.js-trip-query-changed #trip-query-confirm {
	position: fixed;
	bottom: 1.5rem;
	z-index: 1;
	width: 90vw;
}


@media (max-width: 60em) {
	/* As Button */
	details.trip-filters-toggle > summary {
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		background-image: none;
		background-color: var(--color-link-hover);
		color: var(--color-white);
		padding: calc(var(--padding-main) / 2) var(--padding-main);
		border: 1px solid transparent;
		border-radius: var(--button-radius, var(--border-radius));
		min-width: 10em;
		transition: background-color .2s ease-in, opacity .2s ease-in;
		margin-top: 0;

		&:hover {
			background-color: var(--color-link);
		}
	}
}

@media (min-width: 60em) {
	form[name="find-trips"] {
		margin-top: 0; /*3.25rem;*/

		& [type="submit"],
		& [type="reset"] {
			display: inline-block;
			width: auto;
			min-width: 10em;
			margin: 0;
		}

		/* & details,
		& fieldset,
		& > :last-child { margin-top: 0; } */

		& p:last-child {
			text-align: center;

			/* Preserve space for floating submit btn */
			min-height: 8.625rem;
		}
	}

	/* Preserve space for floating submit btn */
	.js-trip-query-changed form[name="find-trips"] p:last-child {
		padding-top: 4.3125rem;
	}

	#trip-query { max-width: 100%; }

	/* #trip-query-confirm,
	#trip-query-confirm + input { margin-top: 0; }
	#trip-query-confirm + input { margin-right: 0; } */
	.js-trip-query-changed #trip-query-confirm {
		width: auto;
	}

	/* HIDE summary of open .trip-filters-toggle */
	.trip-filters-toggle[open] > summary {
		display: none;
	}

}

/**
 * = NEWSLETTER
 * ========================================================================= */
form[name="form-newsletter"] input { width: 15rem; }

/**
 * = WORDPRESS SEARCHFORM
 * ========================================================================= */
form.searchform {
	display: flex;
	justify-content: center;

	& * + * { margin-top: 0; }

	& input[name="s"] {
		width: calc(60% - 1.5rem);
		max-width: 30rem;
		margin-right: 1.5rem;

		& + [type="submit"] {
			width: calc(40% - 1.5rem);
			min-width: 6rem;
			max-width: 20rem;
		}
	}

}
