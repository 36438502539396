/**
 * = List ITCSS <Base> Styles
 *
 * - Unstyled _elements_ only
 * - Inherits (some) from sanitize.css
 *
 * ========================================================================= */

ul,
ol,
menu,
dir,
dl {
	/* YOLO */
	list-style-type: none;
	list-style-position: inside;

	/*
	While logical-props (`padding-inline-start: 0;`)
	make sense. We surely are not using RTL languages on
	this site. Also PostCSS is adding a lot of code and
	requires a dir="ltr" attr
	*/
	padding-left: 0;
}

.c-list,
.article-body ul,
article ul {
	list-style-type: disc;
	list-style-position: outside;
	& ::marker { color: currentColor; }
	& li + li { margin-top: .75em; }
}

/**
 * Definition Lists
 */
dt { font-weight: 500; }
dd { margin-left: 0; }
