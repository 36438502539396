/**
 * = ITCSS <Page> Styles
 *
 * - imported in main index CSS
 * - Generic HTML/Body page layout styles
 *
 * ========================================================================= */

/**
 * = Site SECTIONs
 *
 * Based on Lea Verou's 'wrappers' with calc() and negative 50%
 * .. so that no extra wrapper elements are needed
 * ------------------------------------------------------------------------- */
.l-section {
	/* margin-left: auto;
	margin-right: auto; */
	--side-margin: calc(var(--width-page) / 2);
	padding-left: calc(50% - var(--side-margin));
	padding-right: calc(50% - var(--side-margin));
}

* + .l-section,
.site-footer { margin-top: 6rem; }
.rank-math-breadcrumb + .l-section,
.site-meta,
.site-navigation,
.site-header,
.site-footer .l-section { margin-top: 0; }

/* Use max() if supported */
@supports ( padding: max(calc(50% - 20em), var(--padding-main)) ) {
	.l-section {
		padding-left: max(calc(50% - var(--side-margin)), var(--padding-main));
		padding-right: max(calc(50% - var(--side-margin)), var(--padding-main));
	}
}

/* MODIFIER: restrict inner content width to 928px */
.l-section--narrow { --width-page: var(--width-page-inner, 928px); }

/* MODIFIER: no outer padding/margins */
.l-section--flush { padding-left: 0; padding-right: 0; }

/* MODIFIER: colored background */
.l-section--inverted,
.l-section--accent {
	background-color: var(--color-accent);
	color: var(--color-white);
	padding-top: var(--margin-vertical);
	padding-bottom: var(--margin-vertical);

	& a,
	& .section-heading,
	& .c-button { color: currentColor; }

	& .c-button { border-width: 1px; border-color: currentColor; }

	& h2,
	& h3 {
		/* Decoration  */
		&::after {
			@mixin heading-decoration;
			color: var(--color-white);
		}

		&:not(.section-heading)::after {
			margin: .75rem 0;
		}
	}

	/* ?? */
	/* & ul { padding-left: 1rem; } */
}

/* WHITE heading-decoration */
.l-section--accent h2::after { background-image: var(--heading-decoration-white); }

/* DARK section */
.l-section--inverted {
	background-color: var(--color-grey);
	& a:hover,
	& a:focus,
	& a:active { color: var(--color-link); }
}


/* MODIFIER: Noise background */
.l-section--grunge {
	/* Mobile 1st 'Responsive' Hero Backgrounds, based on variables */
	--bg-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/background-noise.png');
	background-image: var(--background-deco-narrow, none), var(--background-deco-narrow-flipped, none), var(--bg-image);
	background-size: var(--background-deco-narrow-size), var(--background-deco-narrow-size), auto;
	background-position: var(--background-deco-narrow-position), 20vw -2px, 0 0;
	background-repeat: repeat-x, repeat-x, repeat;
	background-attachment: scroll, scroll, fixed;

	padding-top: 9rem;
	padding-bottom: 9rem;

	@media (min-width: 30em) {
		& {
			background-image: var(--background-deco-wide, none), var(--background-deco-wide-flipped, none), var(--bg-image);
			background-size: var(--background-deco-wide-size), var(--background-deco-wide-size), auto;
		}
	}
}


/* MODIFIER: Deco background, Top and Bottom */
.l-section--deco {
	background-image: var(--background-deco-narrow, none), var(--background-deco-narrow-flipped, none), var(--bg-image, none);
	background-size: var(--background-deco-narrow-size), var(--background-deco-narrow-size), auto;
	background-position: var(--background-deco-narrow-position), 20vw -1px, 0 0;
	background-repeat: repeat-x, repeat-x, repeat;

	padding-top: 9rem;
	padding-bottom: 9rem;

	@media (min-width: 30em) {
		& {
			background-image: var(--background-deco-wide, none), var(--background-deco-wide-flipped, none), var(--bg-image, none);
			background-size: var(--background-deco-wide-size), var(--background-deco-wide-size), auto;
		}
	}
}
