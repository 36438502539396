/**
 * = FONT-FACE (generic)
 *
 * (Web) Fonts in use.
 *
 * When using Web-fonts: try to adjust the @font-face syntax to the most apropriate
 * version for your project: e.g. use only `Local`, `Woff` and `Woff2`
 *
 * See: https://css-tricks.com/snippets/css/using-font-face/
 *
 * Check e.g. https://www.fontsquirrel.com/ for great 'free' Web fonts and the best
 * way to use them.
 *
 * Also: when using Google Webfonts: consider adding the fonts to your local project
 * instead of 'hotlinking' 'em with https://google-webfonts-helper.herokuapp.com/
 *
 * ------
 *
 * For this project we use: `Local`, and only `Woff2`!
 *
 * Source Sans Pro & Kalam
 * <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,900;1,300;1,400&display=swap" rel="stylesheet">
 * <link href="https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap" rel="stylesheet">
 *
 * ========================================================================= */

/* Source Sans Pro: latin 300 italic */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/source-sans-pro-v18-latin-300italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Source Sans Pro: latin 400 italic */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/source-sans-pro-v18-latin-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Source Sans Pro: latin 300 */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/source-sans-pro-v18-latin-300.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Source Sans Pro: latin 400 */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/source-sans-pro-v18-latin-regular.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Source Sans Pro: latin 600 */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/source-sans-pro-v18-latin-600.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Source Sans Pro: latin 900 */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/source-sans-pro-v18-latin-900.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* Kalam: latin */
@font-face {
	font-family: 'Kalam';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(/wp-content/themes/360explore-com/static/fonts/kalam-v11-latin-300.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

