/**
 * = Typography ITCSS <Base> Styles
 *
 * - Very Generic Type styles only!
 * - Anything more complex becomes either an Object or Component!
 *
 * ========================================================================= */

/**
 * SMOOTH some bold webfonts
 * ------------------------------------------------------------------------- */
/* .intro,
.subtitle,
.heading,
.payoff { @mixin heading; } */


/**
 * Main Site Title
 * ------------------------------------------------------------------------- */
.site-hero__title,
.faux-hero__title {
	font-size: var(--font-size-h2);
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1;
	text-shadow: 0 0 .2em rgba(0, 0, 0, .2);

	/* Decoration  */
	&::after {
		@mixin heading-decoration;
		width: 90px;
		height: 10px;
	}
}


/**
 * VISUALLY hide Hero Titles on Mobile
 * and show 'em above content
 * On wide screens, the reversed
 * ------------------------------------------------------------------------- */
.site-hero__title,
.site-hero__subtitle { @mixin hidden-visually; }

.faux-hero__title,
.faux-hero__subtitle {
	display: block;
	margin: 0 1.5rem 3rem 1.5rem;
	text-align: center;
	text-shadow: none;

	& + .l-section { margin-top: 0; }
}

/**
 * SubTitle
 * ------------------------------------------------------------------------- */
.subtitle,
.site-hero__subtitle,
.faux-hero__subtitle {
	font-size: var(--font-size-h3);
	font-weight: 300;
	font-style: italic;
	text-transform: none;
}

.site-hero__subtitle {
	font-weight: 400;
	text-shadow: 0 0 .2em rgba(0, 0, 0, .4);
}

@media (min-width: 40em) {
	/* Undo Visually Hidden: HIDE faux- */
	.site-hero__title,
	.site-hero__subtitle { @mixin undo-hidden-visually; }
	.faux-hero__title,
	.faux-hero__subtitle { display: none; }

	.site-hero__title { font-size: var(--font-size-hero); }
	.site-hero__subtitle {
		display: block;
		font-size: var(--font-size-h2);
	}
}
@media (min-width: 50em) {
	.site-hero__title { font-size: var(--font-size-huge); }
	.site-hero__subtitle { font-size: var(--font-size-h1); }
}


/**
 * SubHeading (complementary string before/after Heading)
 * ------------------------------------------------------------------------- */
.sub {
	color: var(--color-grey-medium);
	font-size: var(--font-size-sub);
	font-style: italic;
	font-weight: 400;
	text-transform: capitalize;

	/* Undo Lobotomized Owl: TODO: check*/
	& + * { margin-top: 0; }
}

/* Remove top margin when .sub directly follows a heading */
h1, h2, h3, h4, h5, h6,
[class^="u-h"] { & + .sub { margin-top: 0; } }


/**
 * MAIN CONTENT PARAGRAPH/FLOW TEXT
 * 18px (but we keep counting with html: 16 base)
 * So only override specifically for 'flow text'
 * ------------------------------------------------------------------------- */
p, li, dl, dt, td, th, caption, summary, figcaption {
	font-size: var(--font-size-para);
}

/**
 * Quote
 * TODO: proper component?!
 * ------------------------------------------------------------------------- */
.c-quote {
	color: var(--color-grey-medium);
	padding-top: 96px;
	background-image: var(--data-chevron-quote);
	background-size: 80px;
	background-position: top center;

	&, & > * {
		font-family: var(--font-family-alt);
	}
}

.c-quote blockquote {
	font-size: var(--font-size-h2);
	font-weight: 300;

	/* font-style: italic; */
	text-align: center;
	padding: 0 calc(2 * var(--margin-horizontal));
}
