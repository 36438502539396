/**
 * = 360explore.com ITCSS <SITE Navigation> Styles
 *
 * - 'Faux' mobile 1st menu toggle button with 'checkbox hack'
 * - `#menu-toggle` 		= the (hidden) checkbox
 * - `#menu-toggle.. ~ ul`	= the Menu list to show/hide
 * - `[for="menu-toggle"]`	= the `label` 'button' that toggles the checkbox
 *
 * ========================================================================= */

/**
 * = SKIP link
 * Normally has hidden-visually class but
 * allow the element to be focusable via the keyboard:
 * https://www.drupal.org/node/897638
 * ------------------------------------------------------------------------- */
.skip-link:active,
.skip-link:focus {
	/* stylelint-disable declaration-no-important  */
	clip: auto !important;
	clip-path: none !important;
	height: auto !important;
	margin: 0;
	overflow: visible !important;

	/* position: static !important; */
	width: auto !important;

	/* prevent CLS so position absolute */
	position: absolute !important;
	top: 0;
	left: 0;
	background-color: var(--color-link);
	color: var(--color-white);
	padding: 0 1rem !important;
	border-radius: 0 0 3px 0;
	z-index: 3;

	/* stylelint-enable */
}

/**
 * = 'BUTTONS' in (mobile 1st) navigation
 * ------------------------------------------------------------------------- */

[for="menu-toggle"] { display: inline-block; }

[for="menu-toggle"],
[for="menu-toggle"] svg > *,
.site-search-widget path {
	transition: background-color, color, fill, opacity .3s ease-in;
}

[for="menu-toggle"] svg > *,
svg.o-icon > * {
	transition: background-color, color, fill, opacity, transform .3s ease-in;
}

[for="menu-toggle"],
.site-search-widget .c-button {
	padding: 0;
	background-color: white;
	color: var(--color-link);
	font-size: 1.5rem;
	line-height: 1;

	&:hover,
	&:focus,
	&:active {
		color: var(--color-link-hover);
	}

	& .o-icon {
		vertical-align: middle;
	}

	& .open { 	opacity: 1; transform-origin: 50%; transform: rotate(0); }
	& .closed { opacity: 0; transform-origin: 50%; transform: rotate(45deg); }
}

/* Active mobile menu toggler */
.is-menu-active [for="menu-toggle"] {
	/* MAGIC: Show/Hide SVG path fill */
	--color-menu-open: transparent;
	--color-menu-closed: var(--color-link);
	& .open { fill: transparent; opacity: 0; transform: rotate(-45deg); }
	& .closed { fill: var(--color-link); opacity: 1; transform: rotate(0); }

	/* Align with same button in fixed overlay */
	transform: translate(var(--padding-main), calc( var(--padding-main) - var(--height-menu)));


}

/* Site-Search widget */
.site-search-widget {
	/* Move it all the way to the right again */
	margin-left: auto;
}


/**
 * = NAVIGATION Toggle (mobile 1st)
 * ------------------------------------------------------------------------- */
#menu-toggle:not(:checked) ~ ul { display: none; }
#menu-toggle:checked ~ ul { display: block; display: flex; }

/* 'Float' the menu to prevent Layout Shift */
.site-navigation {

	display: flex;
	justify-content: space-between;
	padding-block-start: var(--padding-main);
	padding-block-end: var(--padding-main);

	/* NOTE: NEEDED AS LONG AS WE KEEP HERO 'FIXED'! */
	background: var(--color-white);
	position: sticky;
	top: 0;
	z-index: 2;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, .1);

	/* CSS PARALLAX */
	/* perspective: 1px; */

	& li {
		color: var(--color-white);
		text-align: center;
		padding: calc(var(--padding-main) / 2) var(--padding-main);

		&::after {
			@mixin heading-decoration;
		}

		/* Hide 'Desktop' Logo Menu Item on Mobile */
		&.site-logo { display: none; }
	}

	& a {
		display: block;
		color: inherit;
		text-decoration: none;
		font-size: var(--font-size-h2);
		font-weight: 300;
		text-transform: uppercase;
	}

	& a:hover,
	& a:focus,
	& a:active {
		color: var(--color-link);

		/* :"( */
		outline: none;
	}

	& li.is-active :any-link,
	& [aria-current] {
		font-weight: 900;
	}

}

/**
 * = NAVIGATION MOBILE 'ACTIVE' (JS)
 * ------------------------------------------------------------------------- */
@media (max-width: 59.99em) {
	.site-navigation nav ul {
		opacity: 0;
		height: 0;
		transition: all .4s;
	}

	/* Prevent scrolling of page when mobile menu is opened */
	.is-menu-active { overflow-y: hidden; }

	.is-menu-active .site-navigation {
		height: 70px;
		position: absolute;
		position: fixed;
		width: 100%;
	}

	.is-menu-active .site-navigation nav {
		position: fixed;
		top: var(--height-menu);
		left: 0;
		right: 0;
		bottom: 0;

		/* TODO: FIXME: ? */
		/* background-image: linear-gradient(to bottom, rgba(5, 10, 20, .9), rgba(0, 0, 0, .95)); */

		z-index: 2;

		& ul {
			opacity: 1;
			background-color: #343E47;
			background-image: url('/wp-content/themes/360explore-com/static/img/backgrounds/mobile-menu.jpg');
			background-size: contain;
			background-position: center bottom;

			/* Align with top menubar */
			transform: translateY(-2.24em);
			height: calc(100vh - var(--height-menu));

			/* display: flex; */
			flex-direction: column;

			/* justify-content: space-around; */
			justify-content: center;
		}
	}
}

/**
 * = NAVIGATION ('Desktop' / 'Wide') > 960px
 * ------------------------------------------------------------------------- */
@media (min-width: 60em) {
	[for="menu-toggle"],
	#menu-toggle { display: none; }

	/* Rule needs to be very specific */
	.site-navigation #menu-toggle ~ ul {
		position: static;
		display: flex;
	}

	/* 'Wider' screen navigation styles */
	.site-navigation {
		display: block;
		background-color: var(--color-background);
		position: sticky;
		top: 0;
		z-index: 2;
		box-shadow: 0 0 3px 0 rgba(0, 0, 0, .1);

		& ul {
			background-color: transparent;
			box-shadow: none;
		}

		& li {
			color: var(--color-text);
			padding: 0;
			border-top-width: 0;

			/*
			4th List Item makes all align-right
			so that the middle keeps space for
			logo

			NOTE: this REQUIRES we keep 3 items left of logo
			and the rest at the right
			*/
			&:nth-child(4) { margin-left: auto; }

			/* Separator borders on all but first/last children */
			&:not(:last-child):not(:nth-child(3)) {
				padding-right: var(--padding-main);
				border-color: var(--color-accent);
				border-right-width: 1px;
				margin-right: var(--padding-main);
			}

			/* Remove scribble underline */
			&::after { display: none; }

			/* Keep space at right for Search Btn */
			&:last-child {
				margin-right: 4.5rem;
			}
		}

		& li a {
			line-height: 1;
			padding: 0 .625rem;
		}

		& li:first-child > a { padding-left: 0; }
		& li:last-child > a { padding-right: 0; }

	}

	/* SEARCH BUTTON */
	.site-search-widget {
		position: relative;

		& .c-button {
			position: absolute;
			right: 0;

			/* Magic: shift up to .site-navigation baseline */
			top: -1.85rem;
		}
	}

}

@media (min-width: 930px) {
	/* HACKY: position faux separator */
	.site-navigation li:not(:last-child):not(:nth-child(3))::after {
		right: -10px;
	}

	/* HACK: prevent contact-us from breaking */
	.site-navigation .page-contact-us a { white-space: nowrap; }
}


/**
 * = BREADCRUMBS (RANKMATH)
 * ------------------------------------------------------------------------- */
.rank-math-breadcrumb {
	font-size: var(--font-size-sub);

	/* text-align: center; */
	padding-bottom: 1.5rem;

	& a {
		font-style: italic;
		color: var(--color-grey-medium);

		&:hover,
		&:focus,
		&:active { color: var(--color-accent); }
	}

	& .separator { color: var(--color-accent); }

	& .last {
		font-style: italic;
	}

	/* Home */
	& a:first-of-type {
		font-weight: 600;
		text-transform: uppercase;
		color: var(--color-grey-medium);

		&:hover,
		&:focus,
		&:active { color: var(--color-accent); }
	}
}
