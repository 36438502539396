/**
 * = text STYLES (utility)
 *
 * ========================================================================= */


/* stylelint-disable declaration-no-important  */

.u-style-italic { font-style: italic !important; }

.u-style-bold { font-weight: bold !important; }

.u-style-uppercase { text-transform: uppercase !important; }
.u-style-lowercase { text-transform: lowercase !important; }

/* stylelint-enable */
