/**
 * = BORDERED LIST (object)
 *
 * This object adds top-borders to list items
 *
 * ========================================================================= */

.o-list-borders { list-style-type: none; padding-left: 0; }
.o-list-borders > * { padding: var(--padding-main) 0; }
.o-list-borders > * + * { border-top-width: 1px; }
.o-list-borders > *:first-child { padding-top: 0; }
