/**
 * = PAGINATION (component)
 *
 * - HTML from pagination.twig
 *
 * ========================================================================= */

.c-pagination {
	@mixin list-reset;
	line-height: 1;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.l-grid + .c-pagination {
	margin-top: 3.75rem;
}

.c-pagination__item,
.c-pagination__link {
	line-height: 1;
	font-weight: 600;
}

.c-pagination__item + .c-pagination__item {
	margin-left: 1.666em;
	margin-top: 0;
}

/* TODO: use 'button' mixin?! */
.c-pagination__link {
	display: inline-block;
	background-color: var(--color-link);
	color: var(--color-white);
	text-decoration: none;
	text-align: center;
	min-width: 1.666em;
	padding: .333em;
	border-radius: 1.5em;

	&:not(.current) {
		background-color: transparent;
		color: var(--color-grey);
	}
}

/**
 * Modifiers
 * ------------------------------------------------------------------------- */
/* .c-pagination__item--first {}
.c-pagination__item--last {}
.c-pagination__item--previous {}
.c-pagination__item--next {}
.c-pagination__item--current {} */

/* Active */
.c-pagination__link:not(.current):hover,
.c-pagination__link:not(.current):focus,
.c-pagination__link:not(.current):active,
.c-pagination__link--active:not(.current) {
	background-color: var(--color-link-hover);
	color: var(--color-white);
}

/* Inactive */
.c-pagination__link--inactive,
.c-pagination__link--inactive:hover,
.c-pagination__link--inactive:focus,
.c-pagination__link--inactive:active {
	background-color: rgba(var(--color-grey-light-val), .25);
	color: var(--color-grey-medium-val);
}

/* Current / More */
.c-pagination__item--current .c-pagination__link,
.c-pagination__item--more .c-pagination__link {
	/* background-color: transparent;
	color: var(--color-text); */
}

/* 'Buttons' different */
.c-pagination__item--first,
.c-pagination__item--last,
.c-pagination__item--previous,
.c-pagination__item--next {

	&  .c-pagination__link {
		/* background-color: var(--color-white); */
		color: var(--color-link);

		&.c-pagination__link--inactive {
			color: var(--color-grey-light);
		}

		&:hover,
		&:focus,
		&:active { background-color: transparent; }

		&:not(.c-pagination__link--inactive):hover,
		&:not(.c-pagination__link--inactive):focus,
		&:not(.c-pagination__link--inactive):active { color: var(--color-link-hover); }

		/* Hide text visually */
		& > span {
			border: 0;
			clip: rect(1px, 1px, 1px, 1px);
			clip-path: inset(50%);
			width: 1px;
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			white-space: nowrap;
		}
	}
}

/* First */
.c-pagination__item--first .c-pagination__link::before { content: "⇠ "; }

/* Last */
.c-pagination__item--last .c-pagination__link::after { content: " ⇢"; }

/* Previous */
.c-pagination__item--previous .c-pagination__link::before { content: "◀"; }

/* Next */
.c-pagination__item--next .c-pagination__link::after { content: "▶"; }
