/**
 * = BUTTON (component)
 *
 * Try and use proper `button` or `input[type="button"]` elements for buttons.
 * The occasional hyperlink (`<a>`) _can_ be styled as a button but must be seen
 * as an 'anti-pattern'.
 *
 * Proper button elements: `button`, `[type="button"]` and `[type="submit"]` do
 * _not_ need a `c-button` class (but it does not harm). `[type="reset"]` works,
 * but should be avoided.
 *
 * @NOTE: We move away from strict ITCSS here, because we *assume* normal `<button>`,
 * `[type="button"]`, etc. should be styled also. Even without class.
 * This makes the CSS more complex though...
 *
 * ========================================================================= */


/**
 * @TODO: remove element selectors on button and just use `.c-button`??
 * ------------------------------------------------------------------------- */
button,
[type="button"],
[type="submit"],
[type="reset"],
.c-button {
	/* stylelint-disable property-no-vendor-prefix */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	/* stylelint-enable */
	display: inline-block;
	vertical-align: middle;
	font: inherit;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background-image: none;
	background-color: var(--color-link);
	color: var(--color-white);

	/*	cursor: pointer; */
	padding: calc(var(--padding-main) / 2) var(--padding-main);
	border: 1px solid transparent;
	border-radius: var(--button-radius, var(--border-radius));

	min-width: 10em;

	box-shadow: none;

	transition: background-color .2s ease-in, opacity .2s ease-in;

	/* Undo Lobotomized Owl */
	margin-top: 0;
}

button:hover,
[type="button"]:hover,
[type="submit"]:hover,
[type="reset"]:hover,
.c-button:hover,
button:focus,
[type="button"]:focus,
[type="submit"]:focus,
[type="reset"]:focus,
.c-button:focus,
button:active,
[type="button"]:active,
[type="submit"]:active,
[type="reset"]:active,
.c-button:active,
.c-button--active {
	background-color: var(--color-grey);

	/* border-color: var(--color-grey); */
	color: var(--color-white);

	/* :"( */
	outline: none;
}


.c-button + .c-button {
	margin-left: 1.5rem;
}

/**
 * Modifiers
 * 1. Icons
 * 2. Inactive
 * 3. Outline
 * ------------------------------------------------------------------------- */

/* [1] ICONS */
.c-button--icon {
	min-width: 0;
}

/* [2] INACTIVE */
button[inactive],
[type="button"][inactive],
[type="submit"][inactive],
[type="reset"][inactive],
.c-button--inactive {
	opacity: .5;

	&:hover,
	&:focus,
	&:active { background-color: var(--color-link); }
}

/* [3] OUTLINE */
/* .c-button--ghost, */
.c-button--outline {
	background-color: transparent;
	color: var(--color-grey);
	border-color: currentColor;
	opacity: .65;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		color: var(--color-grey);
		opacity: 1;
	}
}


/* Color 'fixes' */
[class*="--inverted"] {
	& .c-button { border-color: var(--color-accent); }

	& .c-button--outline {
		border-color: currentColor;
		&:hover,
		&:focus,
		&:active { color: var(--color-white); }
	}
}

[class*="--accent"] .c-button:not(.c-button--outline) {
	background-color: var(--color-white);
	color: var(--color-accent);
	&:hover,
	&:focus,
	&:active {
		background-color: var(--color-grey);
		border-color: var(--color-grey);
		color: var(--color-white);
	}
}
