/**
 * = MEDIA (object)
 *
 * Can be used for about anywhere you need some image next to some text.
 * Optionally reversed.
 *
 * ========================================================================= */


.o-media,
.o-media-list > * {
	position: relative;
	display: flex;
	align-items: flex-start;

	/* align-items: stretch; */

	/* Prevent unwanted vertical space. Redo on e.g. <p> */
	line-height: 1;
}

/* Hyperlink as Media Object: undo link styles */
a.o-media {
	color: inherit;
	font-weight: normal;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active,
	&.is-active {

		& .o-media__body > :first-child { color: var(--color-link); }
	}
}

/* Lists, whose direct children should be Media Objects */
.o-media-list { padding-left: 0; }

.o-media__img {
	margin-right: var(--grid-gap);
	max-width: 45%; /* @TODO is this generic enough? */
	max-width: calc(50% - calc(var(--grid-gap) / 2));

	/* & > img {} =  prevent vertical space */
	& > * { display: block; }
}

.o-media__body {
	flex: 1;

	/*
		- Undo Lobotomized Owl selector
		- align top of body visually with __img
		  (now there's space because of line height etc.)
	*/
	/* margin-top: 0; */
	margin-top: -.125em;
}

.o-media__meta {
	font-size: .75em;

	/* & .c-label { font-size: 1.125em; } */
}


/*
BLOCK-LINKS to make all card clickable
- https://adrianroselli.com/2020/02/block-links-cards-clickable-regions-etc.html
- https://inclusive-components.design/cards/
*/
.o-media__link {
	text-align: right;
	line-height: 1;

	/* `transparent` makes it, in essence, invisible. Which is desired! */
	/* stylelint-disable declaration-no-important  */
	color: transparent !important;
	/* stylelint-enable */

	&::after { @mixin cover; }
}


/**
 * Modifiers
 * 1. Reversed (figure on the right)
 * 2. Flag (body vertically centered on figure)
 * ----------------------------------------------------------------------------
 */

/* 1 */
.o-media--reversed > .o-media__img,
.o-media-list--reversed > * > .o-media__img {
	order: 1;
	margin-right: 0;
	margin-left: var(--grid-gap);
}

/* 2 */
.o-media--flag,
.o-media-list.o-media--flag > * { align-items: stretch; }

.o-media--flag > .o-media__img,
.o-media-list.o-media--flag > * > .o-media__img { display: flex; align-items: center; }

.o-media--flag > .o-media__body,
.o-media-list.o-media--flag > * > .o-media__body,
.o-media-list--reversed > * > .o-media__body { align-self: center; }

