/**
 * = BOXED (mixin)
 *
 * Simple padded, box
 *
 * ========================================================================= */


@define-mixin boxed {
	display: block;
	padding: var(--padding-main) calc(2 * var(--padding-main));
}
