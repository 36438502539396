/**
 * = CLEARFIX (mixin)
 *
 * 'Clears' floats
 *
 * See: http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
 *
 * ========================================================================= */


@define-mixin clearfix {
	&::after {
		content: '';
		display: block;
		clear: both;
	}

	/* Modern browsers have enough with: */
	display: flow-root;
}

