/*
* SETTINGS (global variables)
* Architecture (imports) based on ITCSS
* - https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
*
* Syntax based on BEM, using postcss with vars, nesting
* --------------------------------------------------------------- */
:root {
	/* stylelint-disable declaration-colon-space-after */

	/* Colors: from hex to rgba so that we can use them in var() */
	--color-white-val:						255, 255, 255;
	--color-black-val:						51, 51, 51;

	--color-orange-val:						243, 139, 0; 			/* #F38B00 */
	--color-blue-val:						59, 176, 201; 			/* #3BB0C9 */
	--color-green-val:						170, 173, 0; 			/* #AAAD00 */
	--color-yellow-val:						220, 170, 0; 			/* #DCAA00 */
	--color-red-val:						204, 0, 123; 			/* #CC007B */

	--color-grey-val:						51, 62, 72;				/* #333E48 */
	--color-grey-medium-val:				128, 137, 141; 			/*123, 134, 140;*/			/* #7B868C */
	--color-grey-light-val:					226, 229, 230;			/* #E2E5E6 */

	/* --color-focus-val: 						135, 206, 235;			SkyBlue */
	--color-focus-val: 						var(--color-grey-medium-val);

	--color-white:							rgb(var(--color-white-val));
	--color-black:							rgb(var(--color-black-val));
	--color-orange:							rgb(var(--color-orange-val));
	--color-blue:							rgb(var(--color-blue-val));
	--color-green:							rgb(var(--color-green-val));
	--color-yellow:							rgb(var(--color-yellow-val));
	--color-red:							rgb(var(--color-red-val));
	--color-grey:							rgb(var(--color-grey-val));
	--color-grey-medium:					rgb(var(--color-grey-medium-val));
	--color-grey-light:						rgb(var(--color-grey-light-val));

	--color-warning-bg:						#C23616;
	--color-warning:						#CFD6D8;

	--color-accent:							var(--color-orange);
	--color-accent2:						var(--color-grey);
	--color-highlight:						rgb(255, 253, 231);
	--color-focus:							rgb(var(--color-focus-val));

	/* 'Theme' colors */
	--color-expedition:						var(--color-orange);
	--color-ice:							var(--color-blue);
	--color-snow:							var(--color-blue);
	--color-hike:							var(--color-green);
	--color-micro:							var(--color-green);
	--color-courses:						var(--color-yellow);
	--color-academy:						var(--color-yellow);
	--color-humanity:						var(--color-red);
	--color-run:							var(--color-green);

	--color-background:						var(--color-white);
	--color-text:							var(--color-grey);

	--color-border:							#DDD;
	--color-icon:							var(--color-grey-medium);

	--color-link:							var(--color-accent);
	--color-link-hover:						var(--color-accent2);

	/* Fonts */
	/* --font-family-system:						system-ui, -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"; */
	/* Simpler System Fonts: https://whistlr.info/2020/system-font/ */
	--font-family-system:					"Source Sans Pro", "Segoe UI", system-ui, -apple-system, sans-serif;
	--font-family:							var(--font-family-system);
	--font-family-hd:						var(--font-family);
	--font-family-alt:						"kalam", "Georgia", "serif";

	--font-size-min:						18;
	--font-size-med:						25;
	--font-size-max:						30;

	--line-height:							1.5;

	/* font-size: 18px; line-height: 1.5; */
	--font-size-para:						calc((var(--font-size-min) / 16) * 1rem);

	/* font-size: 15px; line-height: 1; */
	--font-size-sub:						calc((15 / 16) * 1rem);

	/* hero_title		font-size: 40px; line-height: 1; */
	--font-size-hero:						calc((40 / 16) * 1rem);
	--font-size-huge:						calc(((var(--font-size-max) * 2) / 16) * 1rem);

	/* hero_subtitle	font-size: 30px; line-height: 1; */
	--font-size-h1:							calc((var(--font-size-max) / 16) * 1rem);

	/* font-size: 25px; line-height: 1; */
	--font-size-h2:							calc((var(--font-size-med) / var(--font-size-min)) * var(--font-size-para));
	--font-size-h3:							var(--font-size-h2);
	--font-size-h4:							var(--font-size-para);
	--font-size-h5:							var(--font-size-para);
	--font-size-h6:							var(--font-size-para);

	/* Padding */
	--padding-main:							1rem;

	/* Margin */
	--margin-main:							calc(var(--line-height) * 1rem);
	--margin-vertical:						var(--margin-main);
	--margin-horizontal:					var(--padding-main);

	/* Grid */
	--grid-column-count:					auto-fit;
	--grid-column-min:						18em;
	--grid-gap:								calc(2 * var(--margin-horizontal));
	--grid-row-gap:							var(--grid-gap);
	--grid-column-gap:						var(--grid-gap);

	/* Media Queries */
	/* --media-narrow:							30em; 16px base, 30em == 480px */
	--media-narrow:							40em; /* 16px base, 40em == 640px */
	--media-medium:							48em; /* 16px base, 48em == 768px */
	--media-wide:							60em; /* 16px base, 60em == 960px */

	/* Widths */
	--vh:			 						1vh;  /* Overridden by JS */
	--vw:			 						1vw;  /* Overridden by JS */
	--scrollbar-width: 						15px;  /* Overridden by JS */
	--width-page:							90rem; /* 16px base, 90em == 1440px, 60em == 960px */
	--width-page-inner:						58rem; /* 16px base, 928px */
	--width-logo:							80px; /* Mobile first: up to 112px later */

	/* (Mobile) Menu */
	--height-menu:							70px; /* Used for white bar */
	--height-header:						66.66vw; /* 3:2 = 66.66% of viewport _width_*/

	/* Vertical Offset for scroll-targets */
	--scroll-margin-top: 					calc(var(--height-menu, 0) + 2ex);

	/* Avatar/Bio image */
	--size-avatar:							96px;

	/* Borders */
	--border-radius:						3px;
	--button-radius:						25px;

	/* Box-Shadow */

	/* --box-shadow:						0 2px 2px rgba(var(--color-black-val), .2);
	--box-shadow-hover:						0 3px 3px rgba(var(--color-black-val), .3); */

	/* RATING component */
	--rating-max: 							5;
	--rating-size: 							2rem;
	--rating-character:						"●●●●●";
	--rating-color:							var(--color-grey-light);
	--rating-background:					var(--color-accent);

	/* Section BACKGROUND (fallbacks) */
	--background-color: 					var(--color-grey);
	--background-image: 					url('/wp-content/themes/360explore-com/static/img/backgrounds/hero-default-2560x1707.jpg');
	--background-size:	 					contain;
	--background-position: 					50% 70px; /* 70px = Height of Mobile navbar, Desktop: 56px */

	--background-deco-narrow:				url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-narrow-white.png');
	--background-deco-narrow-grey:			url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-narrow-grey.png');
	--background-deco-narrow-flipped:		url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-narrow-white-flipped.png');
	--background-deco-narrow-grey-flipped:	url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-narrow-grey-flipped.png');
	--background-deco-narrow-width:			481px;
	--background-deco-narrow-height:		82px;
	--background-deco-narrow-size:			var(--background-deco-narrow-width) var(--background-deco-narrow-height);
	--background-deco-narrow-position:		50% calc(100% + 2px);

	--background-deco-wide:					url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-wide-white.png');
	--background-deco-wide-grey:			url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-wide-grey.png');
	--background-deco-wide-flipped:			url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-wide-white-flipped.png');
	--background-deco-wide-grey-flipped:	url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-wide-grey-flipped.png');
	--background-deco-wide-width:			1440px;
	--background-deco-wide-height:			90px;
	--background-deco-wide-size:			var(--background-deco-wide-width) var(--background-deco-wide-height);
	--background-deco-wide-position:		50% calc(100% + 2px);

	--background-hero-short:				url('/wp-content/themes/360explore-com/static/img/backgrounds/hero-short.jpg');

	--background-blocks:					url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-blocks.png');
	--background-blocks-flipped:			url('/wp-content/themes/360explore-com/static/img/backgrounds/overlay-blocks-flipped.png');

	/* MASK images (SVG) */
	--mask-background:						var(--color-background);
	--mask-height:							90px; /* 6.25vw == 90px with 1440px width */
	--mask-image-large:						url('/wp-content/themes/360explore-com/static/img/overlay-large.svg');
	--mask-image-small:						url('/wp-content/themes/360explore-com/static/img/overlay-small.svg');
	--mask-image-blocks:					url('/wp-content/themes/360explore-com/static/img/overlay-blocks.svg');

	--mask-cards: 							url('/wp-content/themes/360explore-com/static/img/backgrounds/mask-cards.png');
	--mask-photos: 							url('/wp-content/themes/360explore-com/static/img/backgrounds/mask-photos.png');
	--mask-round:							url('/wp-content/themes/360explore-com/static/img/backgrounds/mask-round.png');

	/* Scribble */
	--heading-decoration:					url('/wp-content/themes/360explore-com/static/img/heading-krabbel.svg');
	--heading-decoration-white:				url('/wp-content/themes/360explore-com/static/img/heading-krabbel-white.svg');

	/* Data URI's */

	--data-chevron-down:					url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#7B868C' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
	--data-chevron-up:						url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#7B868C' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z'/%3E%3C/svg%3E");

	--data-chevron-quote:					url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' fill='#F38B00' width='24px' height='24px'%3E%3Cpath d='M16.78 7.65l1.03 1.8c-2.78 1.89-4.78 3.59-6 5.11-1.21 1.52-1.82 3.05-1.82 4.58 0 .54.08.91.25 1.1.16.19.47.28.92.28.12 0 .36-.01.73-.04.37-.02.65-.04.87-.04 1.53 0 2.77.51 3.71 1.52s1.42 2.35 1.42 4c0 1.89-.55 3.42-1.66 4.6-1.11 1.18-2.56 1.77-4.35 1.77-2.03 0-3.68-.78-4.95-2.33C5.64 28.46 5 26.4 5 23.86c0-3.18.97-6.11 2.92-8.79 1.94-2.68 4.9-5.15 8.86-7.42zm17.12 0l.99 1.8c-2.76 1.89-4.75 3.59-5.98 5.11-1.23 1.52-1.84 3.05-1.84 4.58 0 .54.08.91.25 1.1.16.19.47.28.92.28.14 0 .39-.01.74-.04.35-.02.64-.04.85-.04 1.53 0 2.78.51 3.73 1.52.96 1.01 1.43 2.35 1.43 4 0 1.91-.55 3.45-1.65 4.62-1.1 1.17-2.53 1.75-4.3 1.75-2.05 0-3.73-.78-5.02-2.35-1.3-1.57-1.95-3.61-1.95-6.14 0-3.18.97-6.11 2.92-8.79 1.96-2.66 4.93-5.13 8.91-7.4z'/%3E%3C/svg%3E");

	--data-circle-plus:						url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='%23F38B00' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z'/%3E%3C/svg%3E");
	--data-circle-minus:					url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' width='24px' height='24px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='%23F38B00' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z'/%3E%3C/svg%3E");

	--data-cross-white:						url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' fill='#FFF' width='16px' height='16px'%3E%3Cpath d='M23.1 20L33.5 9.6c.9-.9.9-2.2 0-3.1-.9-.9-2.2-.9-3.1 0L20 16.9 9.6 6.5c-.9-.9-2.2-.9-3.1 0s-.9 2.2 0 3.1l10.4 10.7L6.5 30.4c-.9.9-.9 2.2 0 3.1.3.3.9.6 1.6.6s1.3-.3 1.6-.6L20 22.9l10.4 10.7c.3.3.9.6 1.6.6s1.3-.3 1.6-.6c.9-.9.9-2.2 0-3.1L23.1 20z'/%3E%3C/svg%3E");
	--data-check-white:						url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' fill='#FFF' width='16px' height='16px'%3E%3Cpath d='M16.4 40L1 24.9c-1-1-1-2.2 0-3.2s2.2-.9 3.1 0L15.7 33 35.6 1c.6-.9 1.9-1.3 3.1-.6 1 .6 1.3 1.8.7 3.1L16.4 40z'/%3E%3C/svg%3E");

	/* --data-chevron-down:					"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2313505B' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E";
	--data-chevron-up:						"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2313505B' d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /%3E%3C/svg%3E";
	--data-facebook-white:					"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z' /%3E%3C/svg%3E";
	--data-twitter-white:					"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z' /%3E%3C/svg%3E";
	--data-youtube-white:					"data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='M10,16.5V7.5L16,12M20,4.4C19.4,4.2 15.7,4 12,4C8.3,4 4.6,4.19 4,4.38C2.44,4.9 2,8.4 2,12C2,15.59 2.44,19.1 4,19.61C4.6,19.81 8.3,20 12,20C15.7,20 19.4,19.81 20,19.61C21.56,19.1 22,15.59 22,12C22,8.4 21.56,4.91 20,4.4Z' /%3E%3C/svg%3E"; */

	/* stylelint-enable */
}
