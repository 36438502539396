/**
 * = 360explore.com ITCSS Site Header Styles
 *
 * - Imported in site/index CSS
 * - site-header component-specific styles
 *
 * ========================================================================= */

/**
 * = Site Header
 * .site-header--hero == BIG Image
 * ------------------------------------------------------------------------- */
.site-header {
	background-color: var(--color-grey);
	background-image: var(--background-deco-wide, none), var(--background-hero-short);
	background-size: 100vw 6.25vw, cover;
	background-position: var(--background-deco-wide-position), 100% 100%;
	background-repeat: repeat-x, no-repeat;

	color: var(--color-white);
	text-align: center;

	position: relative;
	overflow: hidden;

	/* Center content */
	display: grid;
	place-items: center;
	padding-top: 8rem;
	padding-bottom: 8rem;

	@media (min-width: 60em) {
		background-size: var(--background-deco-wide-size), cover;
	}

}

.site-header--hero {
	padding-top: 0;
	height: 66.66vw;

	/*
	NOTE: VW takes scrollbar-width into account!

	So: 66.66vw !== 3:2 because it could(!) add
	scrollbar-width (Fx: 15px)
	height: 65.9vw;
	*/
	height: calc(66.66vw - var(--scrollbar-width));

	/* Mobile 1st 'Responsive' Hero Backgrounds, based on variables */
	--bg-image: var(--bg-medium);
	background-image: var(--background-deco-narrow, none), var(--bg-image, var(--background-image, none));
	background-size: var(--background-deco-narrow-size), var(--background-size, contain);
	background-position: var(--background-deco-narrow-position), var(--background-position, 50% 0);
	background-attachment: scroll, fixed;
	background-repeat: repeat-x, no-repeat;

	/*
	--bg-medium: 		460x307
	--bg-medium-large: 	705x470
	--bg-large: 		930x620
	--bg-full: 			1440x960
	--bg-hero: 			1920x1280
	--bg-hero-max: 		2560x1707

	Breakpoints are chosen manually, not 100% precise
	some scaling is unavoidable
	TODO: Check @media (orientation: portrait)  etc...
	*/
	@media (min-width: 30em) {
		& {
			background-image: var(--background-deco-wide, none), var(--bg-image, var(--background-image, none));
			background-size: var(--background-deco-wide-size), var(--background-size, contain);
		}
	}
	@media (min-width: 36em) {  & { --bg-image: var(--bg-medium-large); } }
	@media (min-width: 52em) {  & { --bg-image: var(--bg-large); } }

	@media (min-width: 64em) {  & { --bg-image: var(--bg-full); } }
	@media (min-width: 105em) { & { --bg-image: var(--bg-hero); } }
	@media (min-width: 120em) { & { --bg-image: var(--bg-hero-max); } }

	/*
	RETINA: ~ @2+X
	Scale-up *earlier*
	*/
	/* stylelint-disable */
	@media	(min-width: 30em) and (-webkit-min-device-pixel-ratio: 1.25),
			(min-width: 30em) and (min-device-pixel-ratio: 1.25),
			(min-width: 30em) and (min-resolution: 200dpi) { & { --bg-image: var(--bg-medium-large); } }

	@media	(min-width: 36em) and (-webkit-min-device-pixel-ratio: 1.25),
			(min-width: 36em) and (min-device-pixel-ratio: 1.25),
			(min-width: 36em) and (min-resolution: 200dpi) { & { --bg-image: var(--bg-large); } }

	@media	(min-width: 52em) and (-webkit-min-device-pixel-ratio: 1.25),
			(min-width: 52em) and (min-device-pixel-ratio: 1.25),
			(min-width: 52em) and (min-resolution: 200dpi) { & { --bg-image: var(--bg-full); } }

	@media	(min-width: 64em) and (-webkit-min-device-pixel-ratio: 1.25),
			(min-width: 64em) and (min-device-pixel-ratio: 1.25),
			(min-width: 64em) and (min-resolution: 200dpi) { & { --bg-image: var(--bg-hero); } }

	@media	(min-width: 105em) and (-webkit-min-device-pixel-ratio: 1.25),
			(min-width: 105em) and (min-device-pixel-ratio: 1.25),
			(min-width: 105em) and (min-resolution: 200dpi) { & { --bg-image: var(--bg-hero-max); } }
	/* stylelint-enable */

	/*
	Site-header background is fixed to viewport
	We `contain` it as long as it fits in a 3:2 header

	BUT: when viewport-width is too wide (and short)
		to no longer have our hero fit in 3:2
		we set the background to `100%` to prevent
		'letterboxing'.
		(NOTE: `cover` has issues with `fixed` on iOS?!)
	*/
	@media (min-aspect-ratio: 3/2) { & { --background-size: 100%; } }

}

/*
	Add extra space for when
	1. logged in WP
	2. c-site-banner is visible

	- Admin Bar ~= 46px | 32px (mobile|desktop)
	- Navbar    ~= 70px | 56px

	We ASSUME height of site-banner
	- 1 line  ~=51px
	- 2 lines ~=78px

*/

/* Logged in: 46px + 70px */
.admin-bar .site-header--hero { --background-position: 50% 116px; }

/* Banner visible: 51px + 70px */
/* .c-site-banner ~ .site-header--hero { --background-position: 50% 121px; } */

/* Logged in and Banner visible: 46px + 51px + 70px */
/* .admin-bar .c-site-banner ~ .site-header--hero { --background-position: 50% 167px; } */


@media (min-width: 60em) {
	/* Default: 56px instead of 70px */
	.site-header--hero { --background-position: 50% 56px; }

	/* Logged in: 56px + 32px admin-bar */
	.admin-bar .site-header--hero { --background-position: 50% 88px; }

	/* Banner visible: 56px + 51px banner */
	/* .c-site-banner ~ .site-header--hero { --background-position: 50% 107px; } */

	/* Logged in and Banner visible: 56px + 51px + 32px */
	/* .admin-bar .c-site-banner ~ .site-header--hero { --background-position: 50% 139px; } */
}

/* SIMPLIFY: Banner visible: Make Hero Scroll normally! */
.c-site-banner ~ .site-header--hero {
	--background-position: 50% 0;
	--background-size: cover;
	background-attachment: scroll;
}


.site-header .o-icon { font-size: 1.5em; }


/**
 * = HERO (Content)
 * Make sure title is not too wide...
 * ------------------------------------------------------------------------- */
.site-hero { max-width: 50rem; }
