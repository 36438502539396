/**
 * = HIDDEN VISUALLY (mixin)
 *
 * Hides elements visually but keeps 'em accessible for SR etc.
 * Optionally make focusabble elements visible on focus
 *
 * See: https://gist.github.com/davidhund/fddb4798c3c3a5d11f5092372818cbef
 *
 * ========================================================================= */


/* stylelint-disable declaration-no-important  */
@define-mixin hidden-visually {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	clip-path: inset(50%) !important;
	width: 1px !important;
	height: 1px !important;
	margin: -1px;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
}

@define-mixin undo-hidden-visually {
	/* Allow the element to be focusable via the keyboard:
	* https://www.drupal.org/node/897638 */
	clip: auto !important;
	clip-path: none !important;
	height: auto !important;
	margin: 0;
	overflow: visible !important;
	position: static !important;
	width: auto !important;
	white-space: normal !important;
}
.is-focusable:active,
.is-focusable:focus {
	@mixin undo-hidden-visually;
}

/* stylelint-enable */
