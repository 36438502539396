/**
 * = Image, Figure ITCSS <Base> Styles
 *
 * - Unstyled _elements_ only
 * - Inherits (some) from sanitize.css
 *
 * ========================================================================= */


img, picture, canvas, table, video, audio, iframe, input, select, textarea {
	height: auto;
	max-width: 100%;
}

img {
	/* Offset Alternative text from surrounding text */
	font-style: italic;

	/* remove whitespace below img. */
	vertical-align: middle;
}

figure {
	margin-right: 0;
	margin-left: 0;
}


/*
Undo Sanitize bug with Firefox that inherits <use> > <svg>
See: "Firefox currently allows selection in the Shadow DOM" -> https://fvsch.com/code/svg-icons/how-to/

@TODO: see if we need `inherit` on plain SVG

*/
svg { fill: inherit; }

:not(use) > svg { fill: currentColor; }

/* MASK image with a Grunge effect */
.wp-block-image img,
.c-image-mask {
	mask-image: var(--mask-photos);
	mask-size: cover;
	mask-position: center center;
}

.c-image-mask--round {
	mask-image: var(--mask-round, var(--mask-photos));
}

/* Spacing of ~90px */
* + .wp-block-image.size-large,
* + .wp-block-embed { margin-top: 5.625rem; }
.wp-block-image.size-large + *,
.wp-block-embed + * { margin-top: 5.625rem; }
