/**
 * = VISUALLY HIDDEN (utility)
 *
 * Hides elements visually but keeps 'em accessible for SR etc.
 * Optionally make focusabble elements visible on focus
 *
 * See: https://gist.github.com/davidhund/fddb4798c3c3a5d11f5092372818cbef
 *
 * ========================================================================= */


.u-hidden-visually,
.screen-reader-text {
	@mixin hidden-visually;
}

/**
 * Completely hidden: also from AT
 */
.u-hidden {
	/* stylelint-disable declaration-no-important  */
	display: none !important;
	/* stylelint-enable */
}
