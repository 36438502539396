/**
 * = INLINE LIST (object)
 *
 * This object turns a regular list in a horizontal, inline, list.
 * With the optional Delimiter.
 *
 * ========================================================================= */

.o-list-inline {
	@mixin list-inline;
}


/**
 * Modifiers
 * 1. Delimited (comma) list
 * 2. Delimited (⁞) list
 * 3. Less space between items
 * ----------------------------------------------------------------------------
 */

/* 1. */
.o-list-inline--delimited > * + * {
	margin-left: 0;

	&:before { content: ',\00A0'; }
}

/* 2. */
.o-list-inline--extra a { text-decoration: none; }

.o-list-inline--extra > * + * {
	&:before {
		color: var(--color-accent);
		content: "⁞";
		margin-right: var(--margin-horizontal);
	}

}

/* 3. */
.o-list-inline--compact > * { margin-right: .25em; }
