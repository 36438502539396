/**
 * = LABEL (component)
 *
 * ========================================================================= */


.c-label {
	/* Height ~= 40px: (18px font-size * 1.333 ~= 24px + 2x8px padding) */
	display: inline-block;
	background-color: var(--color-expedition);
	color: var(--color-white);
	font-size: var(--font-size-h4);
	line-height: 1.333;
	font-weight: 600;
	text-transform: uppercase;
	padding: .5rem var(--padding-main);

	transition: background-color .2s ease-in, opacity .2s ease-in;

	&:hover,
	&:focus,
	&:active {
		opacity: .8;
		color: var(--color-white);
	}

	& + & { margin-left: var(--margin-main); }
}

/**
 * Labels are no wider than their content,
 * but sometimes you want them (in a heading) on a
 * new line. display:table Shrinkwrap content and
 * also allows a 'line-break' (as opposed to float).
 *
 * A more modern approach = width: fit-content;
 * or = display: inline-flex;
 * but support is more limited.
 *
 * http://www.456bereastreet.com/archive/201303/how_to_shrinkwrap_and_center_elements_horizontally/
 */

h1,
h2,
h3,
h4,
h5,
h6 {
	& > .c-label {
		/* display: block;
		width: fit-content;*/
		display: table;
	}
}

.c-card__extra .c-label {
	/* 30px */

	/* font-size: 1.5625em; */
	text-align: right;
	width: 100%;
	padding-left: .75em;
	padding-right: calc(var(--padding-main) * 1.5);

	/* border-top-left-radius: 1.5em;
	border-bottom-left-radius: 1.5em; */
	margin: 0;
}

/* @media (max-width: 60em) {
	.o-list-inline .c-label { margin-bottom: .5rem; }
} */

/**
 * MODIFIERS
 * 0. 'Tag' style label
 * 1. Compact label
 * 2. Alternative bg color
 * ----------------------------------------------------------------------------
 */

/* 1. */
.c-label--tag {
	position: relative;
	padding-right: calc(var(--padding-main) + 8px);

	&::after {
		display: block;
		content: "";
		background-color: rgba(255, 255, 255, .2);
		border: 8px solid rgba(255, 255, 255, .2);
		border-top-color: white;
		border-right-color: white;
		position: absolute;
		right: 0;
		top: 0;
	}
}

/* 1. */
.c-label--compact {
	font-size: .875em;
	padding: .25em .5em;
	margin-bottom: .25em;
}

/* 2.
--color-expedition:	var(--color-orange);
--color-ice:		var(--color-blue);
--color-snow:		var(--color-blue);
--color-hike:		var(--color-green);
--color-micro:		var(--color-green);
--color-courses:	var(--color-yellow);
--color-academy:	var(--color-yellow);
--color-humanity:	var(--color-red);

`country` = grey-medium
*/

.c-label--grey,
.c-label--alt {
	background-color: var(--color-accent2);
}
.c-label--grey-medium {
	background-color: var(--color-grey-medium);
}
.c-label--grey-light {
	background-color: var(--color-grey-light);
	color: var(--color-grey);
	&:hover,
	&:focus,
	&:active {
		background-color: var(--color-grey);
		color: var(--color-white);
	}
}
.c-label--expedition,
.c-label--trek {
	background-color: var(--color-expedition);
}
.c-label--ice,
.c-label--snow {
	background-color: var(--color-snow);
}
.c-label--hike,
.c-label--micro {
	background-color: var(--color-hike);
}
.c-label--courses,
.c-label--academy {
	background-color: var(--color-courses);
}
.c-label--human,
.c-label--humanity,
.c-label--marketing {
	background-color: var(--color-humanity);
}
.c-label--run {
	background-color: var(--color-run);
}

/* Semi-trans dark background over other color */
.c-label--shade {
	background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
}

/**
 * GRID for labels: four-up
 * ----------------------------------------------------------------------------
 */
.c-label-grid {
	@mixin list-reset;
	display: flex;
	flex-wrap: wrap;
	gap: 0;
	align-items: baseline;

	& > * {
		flex-basis: 50%;
		overflow: hidden;
		font-size: 1rem;
	}

	& .c-label {
		display: block;
		margin: 1px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

/**
 * MEDIA Queries
 * ----------------------------------------------------------------------------
 */
/* @media ( min-width: 30em ) {
	.c-label { font-size: .75em; }
} */
