/**
 * = CONTAIN wrapper (object)
 *
 * Wraps elements. Keeps them centered and maximizes width and contains floats.
 *
 * ========================================================================= */


.o-contain {
	max-width: var(--width-contain);
	padding: 0 var(--padding-main);
	margin: 0 auto;

	@mixin clearfix;
}
