/**
 * = BIO (component)
 *
 * ========================================================================= */

.c-bio,
.c-bio__link {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.c-bio__link {
	text-decoration: none;
	color: inherit;

	/* Decoration  */
	&::after {
		@mixin heading-decoration;
		margin-top: 1rem;
		margin-bottom: 0;
	}
}

.c-bio__image {
	display: block;

	/* TODO: NOTE: Magic: + distorts non-square images! */
	width: var(--size-avatar, 128px);
	height: var(--size-avatar, 128px);
	border-radius: 100%;
}

.c-bio__description {
	color: var(--color-grey-medium);
	font-style: italic;
	max-width: 30rem;
	text-align: center;
}

.c-bio__name {
	font-size: var(--font-size-h2);
	font-weight: 900;
	text-transform: uppercase;
	line-height: var(--line-height);
	margin: .5em 0;
}
.c-bio__link:hover .c-bio__name,
.c-bio__link:focus .c-bio__name,
.c-bio__link:active .c-bio__name {
	color: var(--color-link);
}

.c-bio__description + .c-bio__name { margin-top: .5rem; }
.c-bio__name + .c-bio__description { margin-top: -.5rem; }

