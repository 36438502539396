/**
 * = RATING (component)
 *
 * (Star) Rating. Based on simple UNICODE character (circle).
 * https://css-tricks.com/five-methods-for-five-star-ratings/
 * @TODO: rating-Rating could/should be enhanced with RDFa/facebook shizz?
 *
 * ========================================================================= */


.c-rating {
	--rating-percent: calc(var(--rating) / var(--rating-max) * 100%);

	display: inline-block;
	font-size: var(--rating-size);
	font-family: Arial;
	line-height: 1;
	vertical-align: text-bottom;

	&::before {
		content: var(--rating-character);
		letter-spacing: .1em;
		background: linear-gradient(90deg, var(--rating-background) var(--rating-percent), var(--rating-color) var(--rating-percent));
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

