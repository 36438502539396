/**
 * = ALL (combined) ITCSS <Generic> Styles
 *
 * - imported in main index CSS
 * - Ground-Zero: resets, box-sizing, etc
 *
 * ========================================================================= */


* {
	box-sizing: border-box;
	background-repeat: no-repeat;

	/**
	 * Default border style for all elements
	 * Now we only need to set a border *width* when we need a border
	 * E.g. .foo { border-width: 1px; } .bar { border-bottom-width: 2px; }
 	 * --------------------------------------------------------------------- */
	border-style: solid;
	border-color: var(--color-border);
	border-width: 0;

	/**
	 * Undo all existing bottom margins.
	 * Single Direction Margins:
	 * http://csswizardry.com/2012/06/single-direction-margin-declarations/
	 * We use top-margins only, see selector below
 	 * --------------------------------------------------------------------- */

	/* margin-top: 0;
	margin-bottom: 0; */

	/* YOLO */
	margin: 0;

	font-family: var(--font-family);
	line-height: var(--line-height);
}


html { scroll-behavior: smooth; }

/*  NOTE: this is not needed for now because of `postcss-100vh-fix`

	https://github.com/jensimmons/cssremedy/issues/70#issuecomment-635337392
	https://twitter.com/bfgeek/status/1262465912025698304

	html { height: -webkit-fill-available; }
	html, body { min-height: 100%; min-height: 100vh; min-height: -webkit-fill-available; }
*/
body {
	min-height: 100vh; /* Fixed by postcss-100vh-fix` */
	overflow-x: hidden;

	color: var(--color-text);
}


/* HIDDEN */
/* stylelint-disable declaration-no-important  */
[hidden] { display: none !important; }
/* stylelint-enable  */

/**
 * Leave space above scroll-target
 * Try height of navbar + default.
 * fall back to reasonable default
 */
/* [id]:not(.l-section) { scroll-margin-top: var(--scroll-margin-top, 2ex); } */
[id] { scroll-margin-top: var(--scroll-margin-top, 2ex); }


/**
 * Lobotomized Owl Selector
 * Add only _top_ margin to block elements:
 * http://alistapart.com/article/axiomatic-css-and-lobotomized-owls
 *
 * This adds a top-margin to all sibling blocks (flow content)
 * and prevents us adding it to all elements/classes separately.
 * It also prevents us having to *undo* it on e.g. :last-child
 *
 * @TODO: test/review this thoroughly (e.g. each `:not()` adds specficity)
 * ------------------------------------------------------------------------- */
/* body *:not(li):not(script):not(style) + *:not(:first-child) { margin-top: var(--margin-vertical); } */
/* main *:not(li):not(script):not(style) + *:not(:first-child) { margin-top: var(--margin-vertical); } */
/* Simplify? */
.site-content * + *,
.stack * + * {
	margin-top: var(--margin-vertical);
}

/* TODO: FIXME: apparently also <br> etc is affected? */

/* UNDO specifically */
br,
hr,
li,
li ul,
li ol,
dd,
:first-child,
script,
style,
script + *,
style + * { margin-top: 0; }

:is(h2, h3, h4, h5) + p { margin-top: .5em; }

abbr { text-decoration: none; }

summary {
	cursor: pointer;

	/* Remove Triangle */
	list-style: none;

	/* Add background SVG (circle) */
	padding-right: 2rem;
	background-repeat: no-repeat;
	background-position: 100% .125em;
}

/* Remove Triangle*/
summary::-webkit-details-marker { display: none; }

/* Add open/close icon */
details > summary { background-image: var(--data-circle-plus); }
details[open] > summary { background-image: var(--data-circle-minus); }
