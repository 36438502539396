/**
 * = CARD(component)
 *
 * Inspired by:
 * - https://inclusive-components.design/cards/
 *
 * ========================================================================= */


.c-card,
.c-card-list > li {
	display: flex;
	flex-direction: column;
	flex-wrap: no-wrap;

	background: var(--color-white);
	color: var(--color-text);
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1);

	position: relative;

	will-change: transform;
	transform: scale(1);
	transition: transform .125s;

	/* Not needed when we have a _white_ read-more
	hyperlink as last child. That still takes up
	some space which acts as bottom-padding ;)
	padding-bottom: var(--padding-main); */

	/* Everything except Media gets some side-space */
	& > *:not(.c-card__media, .c-card__extra) {
		padding-left: calc(1.5 * var(--padding-main));
		padding-right: calc(1.5 * var(--padding-main));
	}

	/* UNDO Lobotomized Owl */
	& * + * { margin-top: 0; }
}

.l-grid > .c-card,
.l-grid > * > .c-card { height: 100%; }

.c-card__heading {
	font-size: var(--font-size-h3);
	text-align: left;
	margin: .5em 0;
}

/* Place image above heading */
.c-card__media {
	order: -1;
	position: relative;

	/* TODO: Investigate! */
	aspect-ratio: 3 / 2;

	/*
	All images euqally high,
	irrespective of aspect-ratio.
	*/
	/* min-height: 40%; */
	& > img {
		width: 100%;
		object-fit: cover;
		mask-image: var(--mask-cards);
		mask-size: cover;
	}
}

.c-card__extra {
	/* ASSUME height of image == 3:2 == 66% */
	position: absolute;

	/* width: 50%; */

	/* Top: 0 but position at _bottom_ of __media with margin-hack */
	top: 0;
	right: 0;

	/* Fallback */
	margin-top: 60%;
	margin-top: calc(66.66% - 2em);

	/* Exactly: 3:2 from top, MINUS half-height of label */
	/* margin-top: calc(66.66% - 1.25rem); */
}

/* Price Label */
.c-price-label {
	color: var(--color-orange);
	font-weight: 600;
	font-size: var(--font-size-h3);
}


.c-card__body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: space-between;

	& p {
		font-size: .888em;
		margin-bottom: .888em;
	}

	& .c-price-label {
		font-size: var(--font-size-h3);
	}

	& .c-price-label {
		margin-top: auto;
		margin-bottom: .25em;
	}

	& .subtitle {
		margin-bottom: .75em;
		flex-grow: 1;
	}

	& .c-card__date {
		font-weight: 600;
		&::after { content: " - "; color: var(--color-grey-medium); }
	}
}

/*
BLOCK-LINKS to make all card clickable
- https://adrianroselli.com/2020/02/block-links-cards-clickable-regions-etc.html
- https://inclusive-components.design/cards/
*/
.c-card__link {
	text-align: right;
	line-height: 1;

	/* `transparent` makes it, in essence, invisible. Which is desired! */
	/* stylelint-disable declaration-no-important  */
	color: transparent !important;
	/* stylelint-enable */

	&::after { @mixin cover; }
}

/* Card/Block-Link HOVER/FOCUS styles */
.c-card:hover {
	transform: scale(1.02);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}
.c-card:focus-within {
	box-shadow: none;

	/* outline: 1px solid var(--color-link); */

	& .c-card__heading { color: var(--color-link); }
}

/**
 * Second-last item
 * (last is usually .c-card__link ..)
 * will align to bottom
 */
.c-card__body > :nth-last-child(2) { margin-top: auto;  }


/**
 * = OVERRIDE: MEDIA-OBJECT-STYLE `post` Cards
 * ------------------------------------------------------------------------- */
.c-card--type-post {
	& .c-card__heading {
		font-size: 20px;
		color: var(--color-accent);
		margin: 1rem 0 .5rem 0;

		/* line-height: 1; */

		&::after { display: none; }
	}
	& .c-card__media {
		& > img { mask-image: none; }
	}

}
@media (min-width: 60em) {
	.c-card--type-post {
		--padding-main: .5rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-content: flex-start;

		/* grid-gap: 0 var(--grid-gap); */

		height: auto;

		& .c-card__heading { margin-top: 0; }

		& .c-card__media {
			/* grid-row: 1 / 3; */
			grid-column: 1;
		}

		& .c-card__body {
			grid-column: 2;
			justify-content: flex-start;
			padding: var(--padding-main);
			padding-bottom: 0;


			& > :nth-last-child(2) { margin-top: 0; }
		}

		/* Make sure it takes up no space */
		/* & .c-card__link {
			font-size: 1px;
			line-height: 1;
		} */
		& .c-card__link {
			padding-right: 1em;
			margin-top: auto;
			margin-bottom: var(--padding-main);

			&::before {
				content: "»";
				position: absolute;
				right: .75em;
			}
			/* stylelint-disable declaration-no-important  */
			font-size: .888em !important;
			color: var(--color-link) !important;
			&:hover,
			&:focus,
			&:active {
				color: var(--color-grey-medium) !important;
			}
			/* stylelint-enable  */
		}
	}

}
