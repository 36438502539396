/**
 * = INLINE LIST (mixin)
 *
 * Places list items inline next to eachother. Used on Lists (UL, OL)
 *
 * ========================================================================= */


@define-mixin list-inline {
	@mixin list-reset;
	font-size: 0;

	& > * {
		display: inline-block;
		font-size: 1rem;

		margin-right: var(--margin-horizontal);
	}

	& > :last-child { margin-right: 0; }

	/* & > * + * { margin-left: var(--margin-horizontal); } */
}
