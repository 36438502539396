/**
 * = Site Banner (component)
 *
 * This component is a simple Site Banner container
 * Content is HTML and comes from Post Snippets
 *
 * ========================================================================= */


.c-site-banner {
	text-align: center;
	padding-top: calc(var(--margin-vertical) / 2);
	padding-bottom: calc(var(--margin-vertical) / 2);
	margin: 0;

	& a { text-decoration: underline; }
	& a.c-button { text-decoration: none; }
}
